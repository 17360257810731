import { Dashboard } from 'src/app/feature/DashBoard/models/DashBoard';

export const GET_DASHBOARD = 'GET_DASHBOARD';
export const RESET_DASHBOARD = 'RESET_DASHBOARD';

interface ActionGetDashboard {
  type: typeof GET_DASHBOARD;
  payload: Dashboard;
}

interface ActionResetDashboard {
  type: typeof RESET_DASHBOARD;
}

export type TypesActionsDashboard = ActionGetDashboard | ActionResetDashboard;
