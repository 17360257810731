import { AxiosResponse } from 'axios';

import { axiosIntance } from 'src/app/core/config/AxiosConfig';
import {
  DataEmployeeId,
  EmployeeSchedule
} from 'src/app/feature/Employees/models/Employee';

export const PayrollRepository = {
  createEmployee: (form: object): Promise<AxiosResponse<DataEmployeeId>> => {
    return axiosIntance.post('/payroll/employees', form);
  },
  updateEmployee: (form: object): Promise<AxiosResponse<DataEmployeeId>> => {
    return axiosIntance.put('/payroll/employees', form);
  },
  deleteEmployee: (data: {
    accountId: string;
    id: string;
  }): Promise<AxiosResponse<{ success: boolean }>> => {
    return axiosIntance.delete('/payroll/employees', { data });
  },
  createSchedule: (form: object): Promise<AxiosResponse<EmployeeSchedule>> => {
    return axiosIntance.post('/payroll/employees/schedule', form);
  },
  deleteSchedule: (data: {
    accountId: string;
    id: string;
  }): Promise<AxiosResponse<{ success: boolean }>> => {
    return axiosIntance.delete('/payroll/employees/schedule', { data });
  }
};
