import { ReactNode } from 'react';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Monitor',
        link: '/panel',
        icon: DashboardTwoToneIcon
      },
      {
        name: 'Órdenes',
        link: '/orders',
        icon: DashboardTwoToneIcon
      },
      {
        name: 'Perfil de Usuario',
        icon: AccountCircleTwoToneIcon,
        link: '/profile'
      }
    ]
  },
];

export default menuItems;
