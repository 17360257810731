import { useRoutes } from 'react-router-dom';
import routesAuth from './routesAuth';
import routesProtected from './routesProtected';
import routesProtectedHost from './routesProtectedHost';
import routesProtectedKitchen from './routesProtectedKitchen';
import routesProtectedManager from './routesProtectedManager';
import routesProtectedPayment from './routesProtectedPayment';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Provider } from 'react-redux';
import store from 'src/app/core/redux/store';
import ThemeProvider from './app/shared/theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import { UsersRole } from './app/core/config/enums';
import { UserAuth } from './app/feature/Users/models/User';

const App = () => {
  const auth = useRoutes(routesAuth);
  const contentProtected = useRoutes(routesProtected);
  const contentProtectedHost = useRoutes(routesProtectedHost);
  const contentProtectedKitchen = useRoutes(routesProtectedKitchen);
  const contentProtectedManager = useRoutes(routesProtectedManager);
  const contentProtectedPayment = useRoutes(routesProtectedPayment);

  const checkAccount = (
    user: UserAuth,
    authRoutes: React.ReactElement<
      any,
      string | React.JSXElementConstructor<any>
    >,
    roleRoutes: React.ReactElement<
      any,
      string | React.JSXElementConstructor<any>
    >
  ) => {
    if (user.allowedAccount.allowed) {
      return roleRoutes;
    } else {
      if (user.role !== UsersRole.OWNER) localStorage.removeItem('user');
      return authRoutes;
    }
  };

  const showRoutes = () => {
    if (!localStorage.getItem('user')) {
      return auth;
    } else {
      const user: UserAuth = JSON.parse(localStorage.getItem('user'));
      switch (user.role) {
        case UsersRole.OWNER:
          return checkAccount(user, contentProtectedPayment, contentProtected);
        case UsersRole.HOST:
          return checkAccount(user, auth, contentProtectedHost);
        case UsersRole.KITCHEN:
          return checkAccount(user, auth, contentProtectedKitchen);
        case UsersRole.MANAGER:
          return checkAccount(user, auth, contentProtectedManager);
      }
    }
  };

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Provider store={store}>{showRoutes()}</Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
export default App;
