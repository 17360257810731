import {
  FoodCategory,
  ResponseFoodCategoriesProps
} from 'src/app/feature/FoodCategories/models/FoodCategory';

export const LIST_FOOD_CATEGORIES = 'LIST_FOOD_CATEGORIES';
export const ADD_FOOD_CATEGORY = 'ADD_FOOD_CATEGORY';
export const DELETE_FOOD_CATEGORY = 'DELETE_FOOD_CATEGORY';
export const UPDATE_FOOD_CATEGORY = 'UPDATE_FOOD_CATEGORY';
export const UPLOAD_IMAGE_FOOD_CATEGORY = 'UPLOAD_IMAGE_FOOD_CATEGORY';
export const RESET_FOOD_CATEGORIES = 'RESET_FOOD_CATEGORIES';

interface ActionListFoodCategories {
  type: typeof LIST_FOOD_CATEGORIES;
  payload: ResponseFoodCategoriesProps;
}

interface ActionAddFoodCategories {
  type: typeof ADD_FOOD_CATEGORY;
  payload: FoodCategory;
}

interface ActionDeleteFoodCategories {
  type: typeof DELETE_FOOD_CATEGORY;
  payload: string;
}
interface ActionUpdateFoodCategories {
  type: typeof UPDATE_FOOD_CATEGORY;
  payload: FoodCategory;
}

interface ActionUploadImageFoodCategory {
  type: typeof UPLOAD_IMAGE_FOOD_CATEGORY;
  payload: FoodCategory;
}

interface ActionResetFoodCategories {
  type: typeof RESET_FOOD_CATEGORIES;
}

export type TypesActionsFoodCategories =
  | ActionListFoodCategories
  | ActionAddFoodCategories
  | ActionDeleteFoodCategories
  | ActionUpdateFoodCategories
  | ActionUploadImageFoodCategory
  | ActionResetFoodCategories;
