import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';

import store from '../redux/store';
import { resetAccountsState } from '../redux/actions/accounts/AccountsActions';
import { resetAssistancesState } from '../redux/actions/assistance/AssistanceActions';
import { resetDashboard } from '../redux/actions/dashboard/DashboardActions';
import { resetFoodCategories } from '../redux/actions/foodCategories/FoodCategoriesActions';
import { resetFoodComplements } from '../redux/actions/foodComplements/FoodComplementsActions';
import { resetFoods } from '../redux/actions/foods/FoodsActions';
import { resetInvoices } from '../redux/actions/invoice/InvoiceActions';
import { resetOrders } from '../redux/actions/orders/OrdersActions';
import { resetReports } from '../redux/actions/reports/ReportActions';
import { resetSettings } from '../redux/actions/settings/SettingActions';
import { resetTables } from '../redux/actions/tables/TablesActions';
import { clearUserAuth } from '../redux/actions/users/UsersActions';
import { resetUsers } from '../redux/actions/users/UsersActions';
import { resetPayrollState } from '../redux/actions/payroll/PayrollActions';

export type RootState = ReturnType<typeof store.getState>;

type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

interface Props {
  dispatch: Dispatch<AnyAction>;
  accounts?: boolean;
  assistances?: boolean;
  dashboard?: boolean;
  foodCategories?: boolean;
  foodComplements?: boolean;
  foods?: boolean;
  invoices?: boolean;
  orders?: boolean;
  reports?: boolean;
  settings?: boolean;
  tables?: boolean;
  userAuth?: boolean;
  users?: boolean;
  payroll?: boolean;
}

export const resetStoreStates = ({
  dispatch,
  accounts = true,
  assistances = true,
  dashboard = true,
  foodCategories = true,
  foodComplements = true,
  foods = true,
  invoices = true,
  orders = true,
  reports = true,
  settings = true,
  tables = true,
  userAuth = true,
  users = true,
  payroll = true
}: Props) => {
  accounts && dispatch(resetAccountsState());
  assistances && dispatch(resetAssistancesState());
  dashboard && dispatch(resetDashboard());
  foodCategories && dispatch(resetFoodCategories());
  foodComplements && dispatch(resetFoodComplements());
  foods && dispatch(resetFoods());
  invoices && dispatch(resetInvoices());
  orders && dispatch(resetOrders());
  reports && dispatch(resetReports());
  settings && dispatch(resetSettings());
  tables && dispatch(resetTables());
  userAuth && dispatch(clearUserAuth());
  users && dispatch(resetUsers());
  payroll && dispatch(resetPayrollState());
};
