import {
  ADD_FOOD,
  DELETE_FOOD,
  LIST_FOODS,
  TypesActionsFoods,
  UPDATE_FOOD,
  RESET_FOODS
} from './FoodsTypes';
import { Food, ResponseFoodsProps } from 'src/app/feature/Foods/models/Food';
import { FoodsDao } from 'src/app/core/api/foods/dao/foods.dao';
import { FoodsRepository } from 'src/app/core/api/foods/repository/foods.repository';
import Alert from 'src/app/shared/components/MultiplePorpuseAlert';
import { AxiosResponse } from 'axios';

export function listFoods(payload: ResponseFoodsProps): TypesActionsFoods {
  return {
    type: LIST_FOODS,
    payload
  };
}

export function addNewFood(foodComplement: Food): TypesActionsFoods {
  return {
    type: ADD_FOOD,
    payload: foodComplement
  };
}
export function updateFood(foodComplement: Food): TypesActionsFoods {
  return {
    type: UPDATE_FOOD,
    payload: foodComplement
  };
}

export function deleteFood(foodComplement: string): TypesActionsFoods {
  return {
    type: DELETE_FOOD,
    payload: foodComplement
  };
}

export function resetFoods(): TypesActionsFoods {
  return {
    type: RESET_FOODS
  };
}

export function listFoodsAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null
) {
  return async function (dispacth: Function) {
    await FoodsDao.list(accountId, numberPage, limit, search).then(
      (response: AxiosResponse<ResponseFoodsProps>) => {
        dispacth(listFoods(response.data));
      }
    );
  };
}

export function deleteFoodsAsync(accountId: string, data: object) {
  return function (dispacth: Function) {
    FoodsRepository.delete(data).then(async (response: AxiosResponse<Food>) => {
      Alert({
        title: 'Hecho',
        text: `Producto del menú eliminado con éxito`,
        icon: 'success',
        timer: 3000
      });
      await FoodsDao.list(accountId, null, null, null).then(
        (response: AxiosResponse<ResponseFoodsProps>) => {
          dispacth(listFoods(response.data));
        }
      );
    });
  };
}

export function createFoodsAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<Food> = await FoodsRepository.create(form);
      dispacth(addNewFood(response.data));
      Alert({
        title: 'Hecho',
        text: `Producto ${response.data.name} creado con éxito`,
        icon: 'success',
        timer: 3000
      }).then(() => callback());
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      setValidate(true);
    }
  };
}

export function updateFoodsAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispacth: Function) {
    const response: AxiosResponse<Food> = await FoodsRepository.update(form);
    try {
      dispacth(updateFood(response.data));
      Alert({
        title: 'Hecho',
        text: `Producto ${response.data.name} actualizado con éxito`,
        icon: 'success',
        timer: 3000
      }).then(() => callback());
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      setValidate(true);
    }
  };
}

export function showFoodsAsync(id: string, callback: Function) {
  return function (dispacth: Function) {
    FoodsDao.show(id)
      .then((response: AxiosResponse<Food>) => {
        dispacth(addNewFood(response.data));
      })
      .catch(({ request }) => {
        Alert({
          title: 'Error',
          text: `${JSON.parse(request.response).message}`,
          icon: 'error'
        });
        callback();
      });
  };
}
