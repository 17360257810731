import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, CircularProgress } from '@mui/material';

interface SuspenseLoaderProps {
  color?:
    | 'primary'
    | 'secondary'
    | 'inherit'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
}

const SuspenseLoader: React.FC<SuspenseLoaderProps> = ({
  color = 'primary'
}): JSX.Element => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{ position: 'fixed', left: 0, top: 0, width: '100%', height: '100%' }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={64} disableShrink thickness={3} color={color} />
    </Box>
  );
};

export default SuspenseLoader;
