import {
  ADD_FOOD_COMPLEMENT,
  DELETE_FOOD_COMPLEMENT,
  LIST_FOOD_COMPLEMENTS,
  TypesActionsFoodComplements,
  UPDATE_FOOD_COMPLEMENT,
  RESET_FOOD_COMPLEMENTS
} from './FoodComplementsTypes';
import {
  FoodComplement,
  ResponseFoodComplementsProps
} from 'src/app/feature/FoodComplements/models/FoodComplement';
import { FoodComplementsDao } from 'src/app/core/api/foodComplements/dao/foodComplements.dao';
import { FoodComplementsRepository } from 'src/app/core/api/foodComplements/repository/foodComplements.repository';
import Alert from 'src/app/shared/components/MultiplePorpuseAlert';
import { AxiosResponse } from 'axios';

export function listFoodComplements(
  payload: ResponseFoodComplementsProps
): TypesActionsFoodComplements {
  return {
    type: LIST_FOOD_COMPLEMENTS,
    payload
  };
}

export function addNewFoodComplement(
  foodComplement: FoodComplement
): TypesActionsFoodComplements {
  return {
    type: ADD_FOOD_COMPLEMENT,
    payload: foodComplement
  };
}
export function updateFoodComplement(
  foodComplement: FoodComplement
): TypesActionsFoodComplements {
  return {
    type: UPDATE_FOOD_COMPLEMENT,
    payload: foodComplement
  };
}

export function deleteFoodComplement(
  foodComplement: string
): TypesActionsFoodComplements {
  return {
    type: DELETE_FOOD_COMPLEMENT,
    payload: foodComplement
  };
}

export function resetFoodComplements(): TypesActionsFoodComplements {
  return {
    type: RESET_FOOD_COMPLEMENTS
  };
}

export function listFoodComplementsAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null
) {
  return async function (dispacth: Function) {
    await FoodComplementsDao.list(accountId, numberPage, limit, search).then(
      (response: AxiosResponse<ResponseFoodComplementsProps>) => {
        dispacth(listFoodComplements(response.data));
      }
    );
  };
}

export function deleteFoodComplementsAsync(accountId: string, data: object) {
  return function (dispacth: Function) {
    FoodComplementsRepository.delete(data).then(
      async (response: AxiosResponse<FoodComplement>) => {
        Alert({
          title: 'Hecho',
          text: `Acompañamiento eliminado con éxito`,
          icon: 'success',
          timer: 3000
        });
        await FoodComplementsDao.list(accountId, null, null, null).then(
          (response: AxiosResponse<ResponseFoodComplementsProps>) => {
            dispacth(listFoodComplements(response.data));
          }
        );
      }
    );
  };
}

export function createFoodComplementsAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<FoodComplement> =
        await FoodComplementsRepository.create(form);
      dispacth(addNewFoodComplement(response.data));
      Alert({
        title: 'Hecho',
        text: `Acompañamiento "${response.data.name}" creado con éxito`,
        icon: 'success',
        timer: 3000
      }).then(() => callback());
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      setValidate(true);
    }
  };
}

export function updateFoodComplementsAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<FoodComplement> =
        await FoodComplementsRepository.update(form);
      dispacth(updateFoodComplement(response.data));
      Alert({
        title: 'Hecho',
        text: `Acompañamiento "${response.data.name}" actualizado con éxito`,
        icon: 'success',
        timer: 3000
      }).then(() => callback());
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      setValidate(true);
    }
  };
}

export function showFoodComplementsAsync(id: string, callback: Function) {
  return function (dispacth: Function) {
    FoodComplementsDao.show(id)
      .then((response: AxiosResponse<FoodComplement>) => {
        dispacth(addNewFoodComplement(response.data));
      })
      .catch(({ request }) => {
        Alert({
          title: 'Error',
          text: `${JSON.parse(request.response).message}`,
          icon: 'error'
        });
        callback();
      });
  };
}
