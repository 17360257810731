import { AxiosResponse } from 'axios';

import {
  ADD_ORDER,
  DELETE_ORDER,
  LIST_ORDERS,
  TypesActionsOrders,
  UPDATE_ORDER,
  SET_PANEL,
  ORDER_NOTIFICATIONS,
  CLEAR_ORDER_NOTIFICATIONS,
  HIDE_VOID_ORDER,
  RESET_ORDERS
} from './OrdersTypes';
import {
  Order,
  ResponseOrdersProps,
  ChangeStatusFoodObject,
  OrdersList
} from 'src/app/feature/Orders/models/Orders';
import { OrdersDao } from 'src/app/core/api/orders/dao/orders.dao';
import { OrdersRepository } from 'src/app/core/api/orders/repository/orders.repository';
import Alert from 'src/app/shared/components/MultiplePorpuseAlert';
import ToastifyAlert from 'src/app/shared/components/MultiplePorpuseAlert/ToastifyAlert';
import { tieneInterseccion } from 'src/app/core/config/helpers';
import { Food } from 'src/app/feature/Foods/models/Food';

const getInterception = (categories: string[], auxCategories: string[]) => {
  if (!categories.length) {
    return true;
  } else {
    return tieneInterseccion(categories, auxCategories);
  }
};

export function listOrders(payload: ResponseOrdersProps): TypesActionsOrders {
  return {
    type: LIST_ORDERS,
    payload
  };
}

export function addNewOrder(Order: Order): TypesActionsOrders {
  return {
    type: ADD_ORDER,
    payload: Order
  };
}
export function updateOrder(Order: Order): TypesActionsOrders {
  return {
    type: UPDATE_ORDER,
    payload: Order
  };
}

export function deleteOrder(Order: string): TypesActionsOrders {
  return {
    type: DELETE_ORDER,
    payload: Order
  };
}

export function panelOrder(Orders: Order[]): TypesActionsOrders {
  return {
    type: SET_PANEL,
    payload: Orders
  };
}

export function listOrderNotifications(
  payload: OrdersList[]
): TypesActionsOrders {
  return {
    type: ORDER_NOTIFICATIONS,
    payload
  };
}

export function clearOrderNotifications(): TypesActionsOrders {
  return { type: CLEAR_ORDER_NOTIFICATIONS };
}

function hideVoidPanelOrder(payload: Order): TypesActionsOrders {
  return {
    type: HIDE_VOID_ORDER,
    payload
  };
}

export function resetOrders(): TypesActionsOrders {
  return { type: RESET_ORDERS };
}

export function listOrdersAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    await OrdersDao.list(accountId, numberPage, limit, search, filter).then(
      (response: AxiosResponse<ResponseOrdersProps>) => {
        dispacth(listOrders(response.data));
      }
    );
  };
}

export function deleteOrdersAsync(accountId: string, data: object) {
  return function (dispacth: Function) {
    OrdersRepository.delete(data).then(
      async (response: AxiosResponse<Order>) => {
        Alert({
          text: `Orden eliminada con éxito`,
          timer: 3000
        });
        await OrdersDao.list(accountId, null, null, null, null).then(
          (response: AxiosResponse<ResponseOrdersProps>) => {
            dispacth(listOrders(response.data));
          }
        );
      }
    );
  };
}

export function createOrdersAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<Order> = await OrdersRepository.create(
        form
      );
      dispacth(addNewOrder(response.data));
      Alert({
        text: `Orden nro ${response.data.code} creada con éxito`,
        timer: 3000
      }).then(() => callback());
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      setValidate(true);
    }
  };
}

export function updateOrdersAsync(form: Order, callback: Function) {
  return async function (dispacth: Function) {
    try {
      const order: AxiosResponse<Order> = await OrdersRepository.update(form);
      dispacth(updateOrder(order.data));
      Alert({
        text: `Orden nro ${order.data.code} actualizada a ${order.data.status} con éxito`,
        timer: 3000
      }).then(() => {
        callback();
      });
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
    }
  };
}

export function updateOrdersPanelKitchenAsync(
  form: Order,
  categoryId: string,
  callback?: Function,
  updateOrderDetail?: Function
) {
  return async function (dispacth: Function) {
    try {
      const copyForm = { ...form };
      const originalOrder: AxiosResponse<Order> = await OrdersDao.show(
        form._id
      );
      copyForm.foods = originalOrder.data.foods;
      const response: AxiosResponse<Order> = await OrdersRepository.update(
        copyForm
      );
      const order = response.data;
      order.foods = form.foods;
      ToastifyAlert({ text: `Orden ${order.code} actualizada` });
      dispacth(updateOrder(order));
      updateOrderDetail && updateOrderDetail(order);
      callback && callback();
    } catch ({ request }) {
      if (request) {
        ToastifyAlert({
          text: `${JSON.parse(request.response).message}`,
          type: 'error'
        });
      }
    }
  };
}

export function changeFoodStatusPanelKitchenAsync(
  form: ChangeStatusFoodObject,
  categoryId: string,
  callback?: Function,
  updateOrderDetail?: Function
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<Order> =
        await OrdersRepository.changeFoodStatus(form);
      const order = response.data;
      let newFoods: Food[] = [];
      let categories: string[] = !categoryId.length
        ? []
        : JSON.parse(categoryId);

      for (let j = 0; j < order.foods.length; j++) {
        const auxCategories: string[] = order.foods[j].foodCategoryId;
        if (
          getInterception(categories, auxCategories) === true &&
          !order.foods[j].orderStatus &&
          order.foods[j].orderStatus !== 'Deleted' &&
          order.foods[j].orderStatus !== 'Entregado'
        ) {
          newFoods.push(order.foods[j]);
        }
      }
      order.foods = newFoods;
      ToastifyAlert({ text: `Orden ${order.code} actualizada` });
      dispacth(updateOrder(order));
      if (!order.foods.length) {
        dispacth(hideVoidPanelOrder(order));
      }
      updateOrderDetail && updateOrderDetail(order);
      callback && callback();
    } catch ({ request }) {
      if (request) {
        ToastifyAlert({
          text: `${JSON.parse(request.response).message}`,
          type: 'error'
        });
      }
    }
  };
}

export function showOrdersAsync(id: string, callback: Function) {
  return function (dispacth: Function) {
    OrdersDao.show(id)
      .then((response: AxiosResponse<Order>) => {
        dispacth(addNewOrder(response.data));
      })
      .catch(({ request }) => {
        Alert({
          title: 'Error',
          text: `${JSON.parse(request.response).message}`,
          icon: 'error'
        });
        callback();
      });
  };
}

export function changeFoodStatusAsync(
  form: ChangeStatusFoodObject,
  callback: Function
) {
  return async function (dispacth: Function) {
    try {
      const order: AxiosResponse<Order> =
        await OrdersRepository.changeFoodStatus(form);
      dispacth(updateOrder(order.data));
      Alert({
        text: `Orden nro ${order.data.code} actualizada con éxito`,
        timer: 3000
      }).then(() => callback());
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
    }
  };
}

export function completeElectronicInvoiceAsync(id: string, callback: Function) {
  return async function (dispatch: Function) {
    try {
      const order: AxiosResponse<Order> =
        await OrdersRepository.completeElectronicInvoice(id);
      dispatch(updateOrder(order.data));
      Alert({ text: 'Se ha generado la factura con éxito.', timer: 3000 }).then(
        () => callback()
      );
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
    }
  };
}

export function getOrdersPanelAsync(categoryId?: string) {
  return async function (dispatch: Function) {
    try {
      const categories: string[] = !categoryId.length
        ? []
        : JSON.parse(categoryId);
      const response: AxiosResponse<Order[]> = await OrdersDao.panel(
        categoryId
      );
      let orders = response.data;
      for (let i = 0; i < orders.length; i++) {
        const foods = orders[i].foods;
        let newFoods: Food[] = [];
        for (let j = 0; j < foods.length; j++) {
          const auxCategories: string[] = foods[j].foodCategoryId;
          if (
            getInterception(categories, auxCategories) === true &&
            foods[j].orderStatus !== 'Deleted' &&
            foods[j].orderStatus !== 'Entregado'
          ) {
            newFoods.push(foods[j]);
          }

          orders[i].foods = [...newFoods];
        }
        newFoods = [];
      }
      orders = orders.filter((order) => order.foods.length > 0);
      dispatch(panelOrder(orders));
    } catch ({ request }) {
      if (request) {
        ToastifyAlert({
          text: `${JSON.parse(request.response).message}`,
          type: 'error'
        });
      }
    }
  };
}

export async function getOrdersExcel(
  accountId: string,
  search: string | null,
  filter: string | null
) {
  try {
    return await OrdersDao.getExcel(accountId, search, filter);
  } catch ({ request }) {
    Alert({
      title: 'Error',
      text: `${JSON.parse(request.response).message}`,
      icon: 'error'
    });
  }
}

export async function getOrdersInvoices(
  dateFrom: string | null = '',
  dateTo: string | null = '',
  accounts: string[] = []
) {
  try {
    let query = '';
    if (accounts && Array.isArray(accounts) && accounts.length > 0) {
      accounts.forEach((account) => {
        query += `&account=${account}`;
      });
    }
    return await OrdersDao.getOrdersInvoices(dateFrom, dateTo, query);
  } catch ({ request }) {
    Alert({
      title: 'Error',
      text: `${JSON.parse(request.response).message}`,
      icon: 'error'
    });
  }
}

export async function getOrdersReport(
  dateFrom: string | null = '',
  dateTo: string | null = '',
  accounts: string[] = []
) {
  try {
    let query = '';
    if (accounts && Array.isArray(accounts) && accounts.length > 0) {
      accounts.forEach((account) => {
        query += `&account=${account}`;
      });
    }
    return await OrdersDao.getOrdersReport(dateFrom, dateTo, query);
  } catch ({ request }) {
    Alert({
      title: 'Error',
      text: `${JSON.parse(request.response).message}`,
      icon: 'error'
    });
  }
}
