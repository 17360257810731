import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';
import SidebarLayout from 'src/app/shared/layouts/SidebarLayout';
import BaseLayout from 'src/app/shared/layouts/BaseLayout';
import SuspenseLoader from 'src/app/shared/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

// Status

const Status404 = Loader(
  lazy(() => import('src/app/shared/content/pages/Status/Status404'))
);

//Payments
const PaymentsCreate = Loader(
  lazy(() => import('src/app/feature/Payments/CreateAllowed/pages/Main'))
);

//Profile
const Profile = Loader(
  lazy(() => import('src/app/feature/ProfileEdit/pages/ProfileEditPage'))
);

const routesProtectedPayment: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/payments" replace />
      },
      {
        path: '/dashboard',
        element: <Navigate to="/payments" replace />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: '/payments',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <PaymentsCreate />
      }
    ]
  },
  {
    path: 'profile',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Profile />
      }
    ]
  }
];

export default routesProtectedPayment;
