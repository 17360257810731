import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import style from './style.module.scss';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  return (
    <LogoWrapper to="/">
      <Container>
        <img
          alt="Mozzo"
          src="/static/images/logo/logo-mozzo.png"
          className={style.logo}
        />
      </Container>
    </LogoWrapper>
  );
}

export default Logo;
