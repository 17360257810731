import { axiosIntance } from '../../../config/AxiosConfig';

export const UserRepository = {
  create: (form: object) => {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    };

    return axiosIntance.post(`/users`, form, config);
  },
  delete: (data: any) => {
    return axiosIntance.delete(`/users`, { data: { id: data._id, ...data } });
  },
  update: (form: object) => {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    };
    return axiosIntance.put(`/users`, form, config);
  },
  recoveryPasswordStep1: (email: string) => {
    return axiosIntance.post(`/auth/password/recovery/step1`,{email});
  },
  recoveryPasswordStep2: (data: object) => {
    return axiosIntance.post(`/auth/password/recovery/step2`,{ ...data});
  },
  updateProfile: (form: object) => {
    return axiosIntance.put(`/users/profile`, form);
  },
  changePassword: (form: object) => {
    return axiosIntance.post(`/auth/password/change`, form);
  },
  passwordRecoveryStep1: (email: string) => {
    return axiosIntance.post(`/auth/password/recovery/step1`, { email });
  },
  passwordRecoveryStep2: (data: object) => {
    return axiosIntance.post(`/auth/password/recovery/step2`, { ...data });
  }
  
};
