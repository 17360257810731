import { Suspense, lazy } from 'react';
import SidebarLayout from 'src/app/shared/layouts/SidebarLayout';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';
import BaseLayout from 'src/app/shared/layouts/BaseLayout';
import SuspenseLoader from 'src/app/shared/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Status404 = Loader(
  lazy(() => import('src/app/shared/content/pages/Status/Status404'))
);

// Orders

const Orders = Loader(
  lazy(() => import('src/app/feature/OrdersKitchen/Main/pages/Main'))
);

const PanelKitchen = Loader(
  lazy(() => import('src/app/feature/NewPanelKitchen/Main/pages/Main'))
);

//Profile

const Profile = Loader(
  lazy(() => import('src/app/feature/ProfileEdit/pages/ProfileEditPage'))
);

const routesProtectedKitchen: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/panel" replace />
      },
      {
        path: '/dashboard',
        element: <Navigate to="/orders" replace />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: '/orders',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Orders />
      }
    ]
  },
  {
    path: 'panel',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <PanelKitchen />
      }
    ]
  },
  {
    path: 'profile',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Profile />
      }
    ]
  }
];

export default routesProtectedKitchen;
