import React from 'react';
import Button from '@mui/material/Button';

interface Props {
  btnText: string;
  type?: 'button' | 'submit' | 'reset';
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: 'text' | 'contained' | 'outlined';
  extraSx?: object;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  size?: 'small' | 'medium' | 'large';
  toolTip?: string;
  disabled?: boolean;
}

const BaseButton: React.FC<Props> = ({
  btnText,
  startIcon,
  endIcon,
  className,
  onClick,
  color,
  type = 'button',
  variant = 'contained',
  extraSx = {},
  size = null,
  toolTip = null,
  disabled = false
}) => {
  return (
    <Button
      type={type}
      variant={variant}
      size={size ? size : window.screen.width < 900 ? 'small' : 'medium'}
      startIcon={startIcon}
      endIcon={endIcon}
      className={className}
      onClick={onClick}
      sx={{
        padding: window.screen.width < 900 ? '2px 6px' : '6px 16px',
        ...extraSx
      }}
      color={color}
      title={toolTip}
      disabled={disabled}
    >
      {btnText}
    </Button>
  );
};

export default BaseButton;
