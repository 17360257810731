import moment from 'moment';

export const dateFormat = (date, format = 'yyyy-MM-DD') => {
  return moment(date).utc(date).format(format);
};

export const getDate = (date = null) =>
  date ? moment(date).utc(date) : moment().utc();

export const addTimeDate = (date, time, amount) =>
  moment(date).utc(date).add(amount, time);

export const getDiferenceDate = (initDate, endDate, time) => {
  const init = getDate(initDate);
  const end = getDate(endDate);
  return end.diff(init, time);
};

export const momentInstance = moment;
