import {
  TypesActionsOrders,
  LIST_ORDERS,
  ADD_ORDER,
  DELETE_ORDER,
  UPDATE_ORDER,
  SET_PANEL,
  ORDER_NOTIFICATIONS,
  CLEAR_ORDER_NOTIFICATIONS,
  HIDE_VOID_ORDER,
  RESET_ORDERS
} from '../../actions/orders/OrdersTypes';
import { OrdersState } from '../../models/orders/OrdersState';
import { UserInitialValues } from 'src/app/feature/Users/interfaces/interfaces';
import { PlanInitialValues } from 'src/app/feature/Accounts/Interfaces/interfaces';
import { StatusOrder } from 'src/app/core/config/enums';
import { generalResponse } from 'src/app/feature/Common/models/GeneralResponse';

const initialState: OrdersState = {
  panel: [],
  orders: generalResponse,
  order: {
    _id: '',
    code: '',
    orderType: '',
    description: '',
    total: 0,
    status: '',
    tracking: [],
    createdAt: '',
    account: {
      _id: '',
      id: '',
      name: '',
      documentId: '',
      country: '',
      state: '',
      city: '',
      address: '',
      zip: '',
      phone: '',
      email: '',
      representative: '',
      description: '',
      plan: PlanInitialValues,
      isEnable: false,
      lat: '',
      lon: '',
      status: '',
      image: '',
      banner: '',
      createdAt: '',
      feeServicesQuota: []
    },
    createdById: UserInitialValues,
    food: [],
    foods: [],
    paymentId: '',
    paymentStatus: '',
    tableId: {
      _id: '',
      name: '',
      isEnable: false,
      account: '',
      createdAt: ''
    },
    electronicInvoice: null
  },
  ordersNotifications: []
};

export default function ordersReducer(
  state = initialState,
  action: TypesActionsOrders
) {
  switch (action.type) {
    case LIST_ORDERS: {
      const orders = action.payload;
      return {
        ...state,
        orders
      };
    }
    case DELETE_ORDER: {
      const order = action.payload;
      return {
        ...state,
        orders: {
          docs: [...state.orders.docs.filter((e) => e._id !== order)]
        }
      };
    }
    case HIDE_VOID_ORDER: {
      const order = action.payload;
      let panel = [...state.panel];
      panel = panel?.filter((e) => e._id !== order._id);
      return {
        ...state,
        panel
      };
    }
    case UPDATE_ORDER: {
      const order = action.payload;
      const index = state.orders.docs.findIndex(
        (todo) => todo._id === order._id
      );
      const newArray = [...state.orders.docs]; //making a new array
      newArray[index] = order; //changing value in the new array
      const { docs, ...rest } = state.orders;
      const orders = {
        docs: newArray, //reassingning todos to new array
        ...rest //the rest of properties
      };

      let panel = [...state.panel];
      if (
        order.status === StatusOrder.COMPLETED ||
        order.status === StatusOrder.CANCELLED
      ) {
        panel = panel.filter((e) => e._id !== order._id);
      } else {
        const i = state.panel.findIndex((todo) => todo._id === order._id);
        panel[i] = order;
      }
      return {
        ...state, //copying the orignal state
        orders,
        panel
      };
    }
    case ADD_ORDER: {
      const order = action.payload;
      return {
        ...state,
        order
      };
    }
    // case SET_PANEL: {
    //   const order = action.payload;
    //   let nstate = state;
    //   nstate.panel = order;
    //   return {
    //     ...state,
    //     nstate
    //   };
    // }
    case SET_PANEL: {
      const panel = action.payload;
      return {
        ...state,
        panel
      };
    }
    case ORDER_NOTIFICATIONS: {
      const ordersNotifications = action.payload;
      return {
        ...state,
        ordersNotifications
      };
    }
    case CLEAR_ORDER_NOTIFICATIONS: {
      return {
        ...state,
        ordersNotifications: []
      };
    }
    case RESET_ORDERS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
