import React, { FC, ReactNode, useState, useContext } from 'react';
import { NavLink as RouterLink, Link } from 'react-router-dom';
import clsx from 'clsx';
import { Typography } from '@mui/material';

import { SidebarContext } from 'src/app/shared/contexts/SidebarContext';

import PropTypes from 'prop-types';
import { Button, Badge, Collapse, ListItem } from '@mui/material';

import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { MenuItem } from './items';

import styles from './styles.module.scss';

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  badge?: string;
  open?: boolean;
  active?: boolean;
  name: string;
  items?: MenuItem[];
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  open: openParent,
  active,
  name,
  items = [],
  ...rest
}) => {
  const [menuToggle, setMenuToggle] = useState<boolean>(openParent);

  const { toggleSidebar } = useContext(SidebarContext);

  const toggleMenu = (): void => {
    setMenuToggle((Open) => !Open);
  };

  const isURL = (str: string) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlRegex.test(str);
  };

  if (children) {
    if (items.length && items[0].items && items[0].items.length) {
      return (
        <ListItem component="div" className="Mui-children" key={name} {...rest}>
          <Button
            className={clsx({ 'Mui-active': menuToggle })}
            startIcon={Icon && <Icon />}
            endIcon={
              menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />
            }
            onClick={toggleMenu}
          >
            {name}
          </Button>
          {menuToggle && (
            <Collapse in={menuToggle} className={styles.collapse}>
              {items[0].items?.map((e, i) => {
                return (
                  <Link
                    // className={`${styles.link} MuiListItem-root MuiListItem-gutters MuiListItem-padding css-xdgdwb-MuiListItem-root`}
                    className={styles.link}
                    to={e.link}
                    key={e.name + i}
                  >
                    <Typography
                      component="p"
                      className="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButtonBase-root css-g2ahm5-MuiButtonBase-root-MuiButton-root"
                    >
                      {e.name}
                    </Typography>
                  </Link>
                );
              })}
            </Collapse>
          )}
        </ListItem>
      );
    } else {
      return (
        <ListItem component="div" className="Mui-children" key={name} {...rest}>
          <Button
            className={clsx({ 'Mui-active': menuToggle })}
            startIcon={Icon && <Icon />}
            endIcon={
              menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />
            }
            onClick={toggleMenu}
          >
            {name}
          </Button>
          <Collapse in={menuToggle}>{children}</Collapse>
        </ListItem>
      );
    }
  }
  return (
    <React.Fragment>
      {isURL(link) ? (
        <ListItem component="div" key={name} {...rest}>
          <Button
            title={`Ir a ${link}`}
            onClick={() => window.open(link)}
            startIcon={Icon && <Icon />}
          >
            {`Ir a ${name}`}
            {badge && <Badge badgeContent={badge} />}
          </Button>
        </ListItem>
      ) : (
        <ListItem component="div" key={name} {...rest}>
          <Button
            activeClassName="Mui-active"
            component={RouterLink}
            onClick={toggleSidebar}
            to={link}
            startIcon={Icon && <Icon />}
          >
            {name}
            {badge && <Badge badgeContent={badge} />}
          </Button>
        </ListItem>
      )}
    </React.Fragment>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired
};

SidebarMenuItem.defaultProps = {
  open: false,
  active: false
};

export default SidebarMenuItem;
