import { Product } from 'src/app/feature/Product/models/Product';

export const LIST_PRODUCTS = 'LIST_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';

interface ActionListProducts {
  type: typeof LIST_PRODUCTS;
  payload: Product[];
  countTotalProduct: number;
}

interface ActionAddProducts {
  type: typeof ADD_PRODUCT;
  payload: Product;
}

interface ActionDeleteProducts {
  type: typeof DELETE_PRODUCT;
  payload: Product;
}

export type TypesActionsProducts =
  | ActionListProducts
  | ActionAddProducts
  | ActionDeleteProducts;
