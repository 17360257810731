import { axiosIntance } from '../../../config/AxiosConfig';

export const AccountsDao = {
  list: (
    page: number | string | null,
    limit: number | string | null,
    search: string | null
  ) => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }

    if (!page) {
      page = process.env.REACT_APP_LIST_PAGE_DEFAULT;
    }
    if (!limit) {
      limit = process.env.REACT_APP_LIST_ROW_DEFAULT;
    }
    return axiosIntance.get(`/accounts?page=${page}&limit=${limit}${query}`);
  },
  show: (id: string) => {
    return axiosIntance.get(`/accounts/${id}`);
  },
  getAccountsList: () => {
    return axiosIntance.get(`/accounts/list`);
  },
};
