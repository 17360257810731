import { AxiosResponse } from 'axios';

import { axiosIntance } from 'src/app/core/config/AxiosConfig';
import { FileUrl } from 'src/app/feature/Common/models/GeneralResponse';
import {
  Employee,
  ResponseEmployee,
  ResponseSchedule
} from 'src/app/feature/Employees/models/Employee';

export const PayrollDao = {
  listEmployees: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null
  ): Promise<AxiosResponse<ResponseEmployee>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }

    if (!page) {
      page = process.env.REACT_APP_LIST_PAGE_DEFAULT ?? 1;
    }
    if (!limit) {
      limit = process.env.REACT_APP_LIST_ROW_DEFAULT ?? 20;
    }
    return axiosIntance.get(
      `/payroll/employees?page=${page}&limit=${limit}&accountId=${accountId}${query}`
    );
  },
  getEmployee: (
    accountId: string,
    userId: string
  ): Promise<AxiosResponse<Employee>> => {
    return axiosIntance.get(`/payroll/employees/${accountId}/${userId}`);
  },
  listSchedules: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<ResponseSchedule>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    let queryPage = '';
    if (page) {
      queryPage = `&page=${page}`;
    }
    let queryLimit = '';
    if (limit) {
      queryLimit = `&limit=${limit}`;
    }
    return axiosIntance.get(
      `payroll/employees/schedule/list?accountId=${accountId}${query}${queryPage}${queryLimit}${filter}`
    );
  },
  getExcel: (
    accountId: string,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<FileUrl>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }
    return axiosIntance.get(
      `/payroll/employees/export/excel?accountId=${accountId}${query}${filter}`
      // {
      //   responseType: 'blob' // important
      // }
    );
  }
};
