import {
  ADD_SETTING,
  UPDATE_SETTING,
  LIST_SETTINGS,
  CLEAR_SETTINGS,
  RESET_SETTINGS,
  TypesActionsSettings
} from './SettingTypes';
import { SettingsDao } from 'src/app/core/api/settings/dao/settings.dao';
import { SettingsRepository } from 'src/app/core/api/settings/repository/settings.repository';
import Alert from 'src/app/shared/components/MultiplePorpuseAlert';
import { Setting } from 'src/app/feature/Settings/models/Setting';

function listSettings(payload: Setting[]): TypesActionsSettings {
  return {
    type: LIST_SETTINGS,
    payload
  };
}

function createSetting(payload: Setting): TypesActionsSettings {
  return {
    type: ADD_SETTING,
    payload
  };
}
function updateSetting(payload: Setting): TypesActionsSettings {
  return {
    type: UPDATE_SETTING,
    payload
  };
}

export function clearSettings(): TypesActionsSettings {
  return {
    type: CLEAR_SETTINGS
  };
}

export function resetSettings(): TypesActionsSettings {
  return {
    type: RESET_SETTINGS
  };
}

export function listSettingsAsync(accountId: string) {
  return async function (dispatch: Function) {
    const response = await SettingsDao.list(accountId);
    const data = response.data;
    if (data.length) data[0].settingType = 'kitchen';
    dispatch(listSettings(data));
  };
}

export function createSettingAsync(
  form: object,
  asyncCallback?: (dispatch: Function) => Promise<void>
) {
  return async function (dispatch: Function) {
    try {
      const response = await SettingsRepository.create(form);
      const data = response.data;
      data.settingType = 'kitchen';
      dispatch(createSetting(data));
      asyncCallback && (await asyncCallback(dispatch));
      Alert({ text: 'Configuración agregada con éxito', timer: 3000 });
    } catch (error) {
      Alert({
        title: 'Error',
        // text: `${JSON.parse(request.response).message}`,
        text: JSON.stringify(error),
        icon: 'error'
      });
    }
  };
}

export function updateSettingAsync(
  form: object,
  asyncCallback?: (dispatch: Function) => Promise<void>
) {
  return async function (dispacth: Function) {
    try {
      const response = await SettingsRepository.update(form);
      const data = response.data;
      data.settingType = 'kitchen';
      dispacth(updateSetting(data));
      asyncCallback && (await asyncCallback(dispacth));
      Alert({ text: 'Configuración actualizada con éxito', timer: 3000 });
    } catch ({ request, message }) {
      Alert({
        title: 'Error',
        // text: `${JSON.parse(request.response).message}`,
        text: JSON.stringify(message),
        icon: 'error'
      });
    }
  };
}

export function uploadP12Async(
  form: object,
  asyncCallback?: (dispatch: Function) => Promise<void>
) {
  return async function (dispacth: Function) {
    try {
      const response = await SettingsRepository.upload(form);
      const data = response.data;
      data.settingType = 'kitchen';
      dispacth(updateSetting(data));
      asyncCallback && (await asyncCallback(dispacth));
      Alert({ text: 'Configuración actualizada con éxito', timer: 3000 });
    } catch ({ request, message }) {
      Alert({
        title: 'Error',
        // text: `${JSON.parse(request.response).message}`,
        text: JSON.stringify(message),
        icon: 'error'
      });
    }
  };
}

export function notificationsAsync(
  form: object,
  asyncCallback?: (dispatch: Function) => Promise<void>
) {
  return async function (dispacth: Function) {
    try {
      const response = await SettingsRepository.notifications(form);
      const data = response.data;
      data.settingType = 'kitchen';
      dispacth(updateSetting(data));
      asyncCallback && (await asyncCallback(dispacth));
      Alert({ text: 'Configuración actualizada con éxito', timer: 3000 });
    } catch ({ request, message }) {
      Alert({
        title: 'Error',
        // text: `${JSON.parse(request.response).message}`,
        text: JSON.stringify(message),
        icon: 'error'
      });
    }
  };
}
