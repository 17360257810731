import { Account } from '../../Accounts/models/Accounts';
import { InvoiceStatus } from '../../Payments/models/Payment';
import { PermissionsPos, Setting } from '../../Settings/models/Setting';

export interface BaseUser {
  _id: string;
  documentId: string;
  country: string;
  state: string;
  city: string;
  address: string;
  firstName: string;
  lastName: string;
  zipCode: string;
  status: string;
  isEnable: boolean;
  dob: string;
  account: string[];
  email: string;
  phone: string;
  role: string;
  createdBy: string;
  createdAt: string;
  deletedAt: string;
  image: string;
}

export interface User extends BaseUser {
  __v: number;
  canPrepare: boolean;
  billTo?: BillTo;
  cashRegisterAccess?: CashRegisterAccessProp[];
  permissionsPOS?: PermissionsPos;
}

export interface UserLogin {
  id?: string;
  _id?: string;
  accessToken?: string;
  documentId: string;
  country: string;
  state: string;
  city: string;
  address: string;
  zipCode: string;
  dob: string;
  password: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isEnable: boolean;
  selectedAccount?: string;
  account?: Array<string>;
  accountList?: Array<Account>;
  role: string;
  image: string;
  status: string;
  canPrepare?: boolean;
  allowedAccount?: InvoiceStatus;
  billTo?: BillTo;
  permissionsPOS?: PermissionsPos;
  settings?: Setting;
}
export interface UserAuth {
  id?: string;
  _id?: string;
  accessToken?: string;
  documentId: string;
  country: string;
  state: string;
  city: string;
  address: string;
  zipCode: string;
  dob: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isEnable: boolean;
  selectedAccount?: string;
  account?: Array<Account>;
  accountList?: Array<Account>;
  role: string;
  image: string;
  status: string;
  canPrepare?: boolean;
  allowedAccount?: InvoiceStatus;
  billTo?: BillTo;
  permissionsPOS?: PermissionsPos;
  settings?: Setting;
}

export interface UserList {
  _id?: string;
  documentId: String;
  country: String;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  status?: string;
  image?: string;
}

export interface UserAccountList {
  _id: string;
  documentId: string;
  country: string;
  state: string;
  city: string;
  address: string;
  firstName: string;
  lastName: string;
  zipCode: string;
  status: string;
  isEnable: boolean;
  dob: string;
  account: Array<Account>;
  email: string;
  phone: string;
  role: string;
  image: string;
  createdBy: string;
  createdAt: string;
  deletedAt: string;
  __v: number;
  canPrepare: boolean;
  billTo?: BillTo;
  permissionsPOS?: PermissionsPos[];
}

export interface ResponseUsersProps {
  docs: UserList[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number | null;
  nextPage: number | null;
}

export interface BillTo {
  firstName: string;
  lastName: string;
  address1: string;
  locality: string;
  administrativeArea: string;
  postalCode: string;
  country: string;
  email: string;
  phoneNumber: string;
}

export interface CashRegisterAccessProp {
  accountId: string;
  cashRegisterAccess: CashRegisterAccess[];
}

export interface CashRegisterAccess {
  cashRegisterId: string;
  cashRegisterName: string;
}

export const userInitialStatus: User = {
  _id: '',
  documentId: '',
  country: '',
  state: '',
  city: '',
  address: '',
  firstName: '',
  lastName: '',
  zipCode: '',
  status: '',
  isEnable: false,
  dob: '',
  account: [],
  email: '',
  phone: '',
  role: '',
  image: '',
  createdBy: '',
  createdAt: '',
  deletedAt: '',
  __v: 0,
  canPrepare: false
};
