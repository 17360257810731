import { BaseUser, User, userInitialStatus } from '../../Users/models/User';
import { GeneralResponseProps } from '../../Common/models/GeneralResponse';

export interface ResponseEmployee extends GeneralResponseProps {
  docs: Employee[];
}

export interface Employee extends Omit<BaseUser, 'image'> {
  image?: string;
  dataEmployeeId?: DataEmployeeId | null;
}

export interface DataEmployeeId {
  _id: string;
  userId: string;
  employeeCode: string;
  employeeType: string;
  status: string;
  entryDate: string;
  emergencyContactName: string;
  emergencyContactLastName: string;
  emergencyContactPhone: string;
  salaryType: string;
  salaryAmount: number;
  ibanAccount: string;
  shift: string;
  schedule?: string[];
  accountId: string;
  createdBy: string;
  createdAt: string;
  deletedAt: string;
}

export const employeeInitialStatus: Employee = {
  _id: '',
  documentId: '',
  country: '',
  state: '',
  city: '',
  address: '',
  firstName: '',
  lastName: '',
  zipCode: '',
  status: '',
  isEnable: false,
  dob: '',
  account: [],
  email: '',
  phone: '',
  role: '',
  createdBy: '',
  createdAt: '',
  deletedAt: '',
  dataEmployeeId: null,
  image: ''
};

export const dataEmployeeIdInitialStatus: DataEmployeeId = {
  _id: '',
  userId: '',
  employeeCode: '',
  employeeType: '',
  status: '',
  entryDate: '',
  emergencyContactName: '',
  emergencyContactLastName: '',
  emergencyContactPhone: '',
  salaryType: '',
  salaryAmount: 0,
  ibanAccount: '',
  shift: '',
  schedule: [],
  accountId: '',
  createdBy: '',
  createdAt: '',
  deletedAt: ''
};

export interface ResponseSchedule extends GeneralResponseProps {
  docs: ScheduleItem[];
}

export interface ScheduleItem {
  _id: string;
  employeeId: EmployeeId;
  date: string;
  day: string;
  timeFrom: string;
  timeTo: string;
  accountId: string;
  shift: string;
  employeeData: EmployeeData;
  id: string;
}

interface EmployeeId {
  _id: string;
  userId: string;
  employeeCode: string;
  employeeType: string;
}

interface EmployeeData {
  firstName: string;
  lastName: string;
  image: string;
}

export const scheduleInitialStatus: EmployeeSchedule = {
  id: '',
  accountId: '',
  dateFrom: '',
  dateTo: '',
  schedule: [],
  user: userInitialStatus
};

export interface EmployeeSchedule {
  id: string;
  accountId: string;
  dateFrom: string;
  dateTo: string;
  schedule: Schedule[];
  user: User;
}

export interface Schedule {
  day: string;
  timeFrom: string;
  timeTo: string;
}

export const employeeScheduleInitialStatus: EmployeeSchedule = {
  id: '',
  accountId: '',
  dateFrom: '',
  dateTo: '',
  schedule: [],
  user: userInitialStatus
};
