import { AxiosResponse } from 'axios';

import { axiosIntance } from 'src/app/core/config/AxiosConfig';
import {
  OrderByFood,
  OrderGraph,
  OrderByStatus,
  OrderTrackingResponse,
  SalesByAccount,
  SalesByFoodResponse,
  SalesPerMonth,
  SalesReport,
  InventoryReport,
  PayrollReport
} from 'src/app/feature/Report/models/Report';
import {
  ReportParams,
  ReportParamsPaginate
} from 'src/app/feature/Report/Interfaces/interfaces';

export const ReportsDao = {
  getOrdersGraph: (
    form: ReportParams
  ): Promise<AxiosResponse<OrderGraph[]>> => {
    return axiosIntance.post('/reports/orders/graph', form);
  },
  getOrdersByFood: (
    form: ReportParams
  ): Promise<AxiosResponse<OrderByFood[]>> => {
    return axiosIntance.post('/reports/orders/ordersByFood', form);
  },
  getOrdersByStatus: (
    form: ReportParams
  ): Promise<AxiosResponse<OrderByStatus[]>> => {
    return axiosIntance.post('/reports/orders/ordersByStatus', form);
  },
  getOrdersTracking: (
    form: ReportParamsPaginate
  ): Promise<AxiosResponse<OrderTrackingResponse>> => {
    return axiosIntance.post('/reports/orders/ordersTracking', form);
  },
  getOrdersExcel: (form: ReportParams): Promise<AxiosResponse<Blob>> => {
    return axiosIntance.post('/reports/orders/ordersTrackingPdf', form, {
      responseType: 'blob' // important
    });
  },
  getSalesByAccount: (
    form: ReportParams
  ): Promise<AxiosResponse<SalesByAccount[]>> => {
    return axiosIntance.post('/reports/sales/totalByAccount', form);
  },
  getSalesPerMonth: (
    form: ReportParams
  ): Promise<AxiosResponse<SalesPerMonth>> => {
    return axiosIntance.post('/reports/sales/graphPerMonth', form);
  },
  getSalesByFood: (
    form: ReportParamsPaginate
  ): Promise<AxiosResponse<SalesByFoodResponse>> => {
    return axiosIntance.post('/reports/sales/totalByFoods', form);
  },
  getSalesExcel: (form: ReportParams): Promise<AxiosResponse<Blob>> => {
    return axiosIntance.post('/reports/sales/totalByFoodsPdf', form, {
      responseType: 'blob' // important
    });
  },
  salesReport: (dateFrom: string, dateTo: string, query: string = '') => {
    return axiosIntance.get<SalesReport>(
      `/reports/sales/summary?dateFrom=${dateFrom}&dateTo=${dateTo}${query}`
    );
  },
  inventoryReport: (dateFrom: string, dateTo: string, query: string = '') => {
    return axiosIntance.get<InventoryReport>(
      `reports/inventory/summary?dateFrom=${dateFrom}&dateTo=${dateTo}${query}`
    );
  },
  payrollReport: (
    dateFrom: string,
    dateTo: string,
    userId: string = '',
    query: string = ''
  ) => {
    let userQuery = '';
    if (userId) {
      userQuery = `&userId=${userId}`;
    }
    return axiosIntance.get<PayrollReport>(
      `/reports/payrroll/summary?dateFrom=${dateFrom}&dateTo=${dateTo}${query}${userQuery}`
    );
  }
};
