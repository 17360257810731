import { axiosIntance } from 'src/app/core/config/AxiosConfig';

const config = {     
  headers: { 'content-type': 'multipart/form-data' }
}

export const AccountsRepository = {
  delete: (data: any) => {

    return axiosIntance.delete(`/accounts`, {
      data: {
        id: data._id,
        ...data
      }
    });
  },
  create: (form: object) => {
    return axiosIntance.post(`/accounts`, form, config);
  },
  update: (form: object) => {
    return axiosIntance.put(`/accounts`, form, config);
  }
};
