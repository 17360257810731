import { axiosIntance } from 'src/app/core/config/AxiosConfig';

export const OrdersRepository = {
  delete: (data: any) => {
    return axiosIntance.delete(`/orders`, { data: { id: data._id, ...data } });
  },
  create: (form: object) => {
    return axiosIntance.post(`/orders`, form);
  },
  update: (form: object) => {
    return axiosIntance.put(`/orders`, form);
  },
  changeFoodStatus: (form: object) => {
    return axiosIntance.post('/orders/food/status/change', form);
  },
  completeElectronicInvoice: (id: string) => {
    return axiosIntance.post('/orders/electronicInvoice/complete', { id });
  }
};
