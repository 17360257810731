import * as Yup from 'yup';
import { FormikProps } from 'formik';
import { Account } from '../../Accounts/models/Accounts';
import { UserAuth } from '../../Users/models/User';
import { Invoice, ResponseInvoiceProps } from '../models/Payment';
import {
  AccountSimplifiedPlanValues,
  PlanInitialValues
} from '../../Accounts/Interfaces/interfaces';
import { ChangePlanData, Plan } from '../../Common/models/Plan';

export interface FormProps {
  formikInstance: FormikProps<PaymentFormValues>;
}

export interface ManagementPaymentsCreateProps {
  user: UserAuth;
  invoice: Invoice;
  createPayment: (
    form: object,
    callback: Function,
    setValidate: (value: boolean) => void
  ) => Promise<void>;
  getInvoice: (id: string, callback: Function) => Promise<void>;
  clearInvoice: () => void;
}

export interface ManagementPaymentsCreateAllowedProps {
  user: UserAuth;
  createPayment: (
    form: object,
    callback: Function,
    setValidate: (value: boolean) => void
  ) => Promise<void>;
}

export interface ManagementPaymentsProps {
  user: UserAuth;
  account: Account;
  listInvoices: (
    page: number,
    limit: number,
    accountId: string,
    search: string | null,
    filter: string | null
  ) => Promise<void>;
  invoices: ResponseInvoiceProps;
  getAccount: (id: string, callback: Function) => void;
}

export interface PlanModalProps {
  modalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  changePlan: (data: ChangePlanData, callback: Function) => void;
  plans: Plan[];
  plan: Plan;
  setPlan: React.Dispatch<React.SetStateAction<Plan>>;
  account: Account;
  user: UserAuth;
}

export interface PaymentFormValues {
  number: number;
  invoiceId: string;
  createdAt: string;
  cardNumber: string;
  cardName: string;
  expDate: string;
  cvv: string;
  autoPayment: boolean;
  amount: string;
  deviceFingerprintID: string;
}

export const initialValues: PaymentFormValues = {
  number: null,
  invoiceId: '',
  createdAt: '',
  cardNumber: '',
  cardName: '',
  expDate: '',
  cvv: '',
  autoPayment: false,
  amount: '',
  deviceFingerprintID: '#'
};

export const validationSchema: Yup.SchemaOf<PaymentFormValues> = Yup.object({
  number: Yup.number(),
  invoiceId: Yup.string(),
  createdAt: Yup.string(),
  amount: Yup.string(),
  cardNumber: Yup.string()
    .matches(/^[0-9]+$/, 'Debe ser un número entero')
    .required('Este campo es requerido'),
  cardName: Yup.string().required('Este campo es requerido'),
  cvv: Yup.string()
    .required('Este campo es requerido')
    .matches(/^[0-9]+$/, 'Debe ser un número')
    .min(3, 'Mínimo 3 dígitos')
    .max(4, 'Máximo 4 dígitos')
    .required('Este campo es requerido'),
  expDate: Yup.string()
    .required('Este campo es requerido')
    .matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, 'Formato MM/AA')
    .required('Este campo es requerido'),
  autoPayment: Yup.bool(),
  deviceFingerprintID: Yup.string()
});

export const InvoiceInitialValues: Invoice = {
  _id: '',
  account: AccountSimplifiedPlanValues,
  plan: PlanInitialValues,
  amount: 0,
  status: '',
  transactions: [],
  createdAt: '',
  number: 0,
  __v: 0,
  dueDate: ''
};
