import {
  TypesActionsDashboard,
  GET_DASHBOARD,
  RESET_DASHBOARD
} from '../../actions/dashboard/DashboardTypes';
import { DashboardState } from '../../models/dashboard/DashboardState';

const initialState: DashboardState = {
  dashboard: {
    users: [],
    orders: [],
    seals: [],
    orders_cancelled: []
  }
};

export default function dashboardReducer(
  state = initialState,
  action: TypesActionsDashboard
) {
  switch (action.type) {
    case GET_DASHBOARD: {
      const dashboard = action.payload;
      return {
        ...state,
        dashboard
      };
    }
    case RESET_DASHBOARD: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
