import { UserAuth } from '../../Users/models/User';
import {
  OrderByFood,
  OrderByStatus,
  OrderTrackingResponse,
  SalesByAccount,
  SalesByFoodResponse,
  SalesPerMonth,
  SalesReport,
  InventoryReport,
  PayrollReport
} from '../models/Report';
import { DateSelectorEnum } from 'src/app/core/config/enums';
import { Tab } from 'src/app/shared/components/AccountNavMenu/TabsNavMenu';

export const tabs: Tab[] = [
  { label: 'Últimos 7 días', id: DateSelectorEnum.LastSevenDays },
  { label: 'Este mes', id: DateSelectorEnum.Month },
  { label: 'Mes anterior', id: DateSelectorEnum.LastMonth },
  { label: 'Este año', id: DateSelectorEnum.Year },
  { label: 'Seleccionar rango', id: DateSelectorEnum.Range }
];

export interface ReportProps {
  user: UserAuth;
  ordersByFood: OrderByFood[];
  ordersByStatus: OrderByStatus[];
  ordersTracking: OrderTrackingResponse;
  salesByAccount: SalesByAccount[];
  salesByFood: SalesByFoodResponse;
  salesPerMonth: SalesPerMonth;
  getOrdersByStatus: (
    from: string,
    to: string,
    account: string[]
  ) => Promise<void>;
  getOrdersByFood: (
    from: string,
    to: string,
    account: string[]
  ) => Promise<void>;
  getOrdersTracking: (
    page: number,
    limit: number,
    from: string,
    to: string,
    account: string[]
  ) => Promise<void>;
  getSalesByAccount: (
    from: string,
    to: string,
    account: string[]
  ) => Promise<void>;
  getSalesByFood: (
    page: number,
    limit: number,
    from: string,
    to: string,
    account: string[]
  ) => Promise<void>;
  getSalesPerMonth: (
    from: string,
    to: string,
    account: string[]
  ) => Promise<void>;
}

export interface ReportSalesProps {
  user: UserAuth;
  salesReport: SalesReport;
  getSalesReport: (
    from: string,
    to: string,
    account: string[],
    callbackSuccess?: Function,
    callbackError?: Function
  ) => Promise<void>;
}

export interface ReportInventoryProps {
  user: UserAuth;
  inventoryReport: InventoryReport;
  getInventoryReport: (
    dateFrom: string,
    dateTo: string,
    accounts?: string[],
    callbackSuccess?: Function,
    callbackError?: Function
  ) => Promise<void>;
}

export interface ReportEmployeesProps {
  user: UserAuth;
  payrollReport: PayrollReport;
  getPayrollReport: (
    dateFrom: string,
    dateTo: string,
    accounts?: string[],
    userId?: string,
    callbackSuccess?: Function,
    callbackError?: Function
  ) => Promise<void>;
}

export interface ReportSelectorProps {
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  user: UserAuth;
  setAccounts: React.Dispatch<React.SetStateAction<string[]>>;
}

export interface SelectorProps {
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

export interface DateSelectorProps {
  selected: string;
  getOrdersByStatus: (
    from: string,
    to: string,
    account: string[]
  ) => Promise<void>;
  getOrdersByFood: (
    from: string,
    to: string,
    account: string[]
  ) => Promise<void>;
}

export interface DateWrapperProps {
  selected: string;
  accounts: string[];
  user: UserAuth;
  getOrdersByStatus: (
    from: string,
    to: string,
    account: string[]
  ) => Promise<void>;
  getOrdersByFood: (
    from: string,
    to: string,
    account: string[]
  ) => Promise<void>;
  getSalesByAccount: (
    from: string,
    to: string,
    account: string[]
  ) => Promise<void>;
  getSalesPerMonth: (
    from: string,
    to: string,
    account: string[]
  ) => Promise<void>;
  setFrom: React.Dispatch<React.SetStateAction<string>>;
  setTo: React.Dispatch<React.SetStateAction<string>>;
  filter: string;
}

export interface DateComponentProps {
  accounts: string[];
  user: UserAuth;
  getOrdersByStatus: (
    from: string,
    to: string,
    account: string[]
  ) => Promise<void>;
  getOrdersByFood: (
    from: string,
    to: string,
    account: string[]
  ) => Promise<void>;
  getSalesByAccount: (
    from: string,
    to: string,
    account: string[]
  ) => Promise<void>;
  getSalesPerMonth: (
    from: string,
    to: string,
    account: string[]
  ) => Promise<void>;
  setFrom: React.Dispatch<React.SetStateAction<string>>;
  setTo: React.Dispatch<React.SetStateAction<string>>;
  filter: string;
}

export interface ReportParams {
  from: string;
  to: string;
  account: string[];
}

export interface ReportParamsPaginate extends ReportParams {
  page: number;
  limit: number;
}

export interface OrdersProps {
  ordersByFood: OrderByFood[];
  ordersByStatus: OrderByStatus[];
  ordersTracking: OrderTrackingResponse;
  getOrdersTracking: (
    page: number,
    limit: number,
    from: string,
    to: string,
    account: string[]
  ) => Promise<void>;
  from: string;
  to: string;
  accounts: string[];
  user: UserAuth;
}

export interface SalesProps {
  salesByAccount: SalesByAccount[];
  salesByFood: SalesByFoodResponse;
  salesPerMonth: SalesPerMonth;
  getSalesByFood: (
    page: number,
    limit: number,
    from: string,
    to: string,
    account: string[]
  ) => Promise<void>;
  from: string;
  to: string;
  accounts: string[];
  user: UserAuth;
}

export const salesReportInitialState: SalesReport = {
  totalSales: 0,
  totalInvoices: 0,
  totalOrders: 0,
  averageTicket: 0,
  monthlySales: null,
  monthlySalesByCategory: null,
  topProducts: [],
  topWaiters: [],
  topTables: [],
  hourlySalesByMonth: null,
  averageTicketByMonth: null,
  monthlyCancelledOrders: null,
  paymentMethodPercentages: null,
  orderTypeDistribution: null,
  originOrdersDistribution: null,
  monthlyTaxBreakdown: null,
  monthlySalesCancelled: null
};

export const inventoryReportInitialState: InventoryReport = {
  totalInventoryValue: 0,
  totalMerma: 0,
  top10Products: [],
  topMermaProducts: [],
  productsExpiringSoon: [],
  productsCloseToReorder: [],
  deadInventory: [],
  inventoryByCost: [],
  inventoryByCategoryCost: [],
  mermaByCauses: [],
  topProviders: [],
  purchasesVsSalesByMonth: []
};

export const payrollReportInitialState: PayrollReport = {
  employeeAttendanceData: [],
  workingTime: [],
  salaryData: []
};
