import {
  TypesActionsPayroll,
  LIST_EMPLOYEES,
  ADD_EMPLOYEE,
  DELETE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  LIST_SCHEDULE,
  ADD_SCHEDULE,
  DELETE_SCHEDULE,
  RESET_PAYROLL_STATE,
  LIST_EMPLOYEE_SCHEDULE
} from 'src/app/core/redux/actions/payroll/PayrollTypes';
import { PayrollState } from '../../models/payroll/PayrollState';

import { generalResponse } from 'src/app/feature/Common/models/GeneralResponse';
import {
  employeeInitialStatus,
  scheduleInitialStatus
} from 'src/app/feature/Employees/models/Employee';

const initialState: PayrollState = {
  employees: generalResponse,
  employee: employeeInitialStatus,
  schedule: scheduleInitialStatus,
  schedules: generalResponse,
  employeeSchedule: generalResponse
};

export default function payrollsReducer(
  state = initialState,
  action: TypesActionsPayroll
) {
  switch (action.type) {
    case LIST_EMPLOYEES: {
      const employees = action.payload;
      return {
        ...state,
        employees
      };
    }
    case DELETE_EMPLOYEE: {
      const newArray = [...state.employees.docs];
      const index = newArray?.findIndex(
        (e) => e.dataEmployeeId?._id === action.payload?.dataEmployeeId?._id
      );
      if (index !== -1) {
        newArray[index].dataEmployeeId = null;
      }
      const { docs, ...rest } = state.employees;
      const employees = {
        docs: newArray,
        ...rest
      };
      let employee = { ...state.employee };
      if (employee._id && employee._id === action.payload._id) {
        employee.dataEmployeeId = null;
      }
      return {
        ...state,
        employee,
        employees
      };
    }
    case UPDATE_EMPLOYEE: {
      const index = state.employees.docs.findIndex(
        (todo) => todo._id === action.payload.userId
      );
      const newArray = [...state.employees.docs]; //making a new array
      if (index !== -1) {
        newArray[index].dataEmployeeId = action.payload; //changing value in the new array
      }
      const { docs, ...rest } = state.employees;
      const employees = {
        docs: newArray,
        ...rest
      };
      let employee = { ...state.employee };
      if (employee._id && employee._id === action.payload.userId) {
        employee.dataEmployeeId = action.payload;
      }
      return {
        ...state, //copying the orignal state
        employees,
        employee
      };
    }
    case ADD_EMPLOYEE: {
      const employee = action.payload;
      return {
        ...state,
        employee
      };
    }
    case LIST_SCHEDULE: {
      const schedules = action.payload;
      return {
        ...state,
        schedules
      };
    }
    case ADD_SCHEDULE: {
      const schedule = action.payload;
      return {
        ...state,
        schedule
      };
    }
    case DELETE_SCHEDULE: {
      const newArray = [...state.schedules.docs];
      const index = newArray?.findIndex((e) => e._id === action.payload.id);
      if (index !== -1) {
        newArray.splice(index, 1);
      }
      const { docs, ...rest } = state.schedules;
      const schedules = {
        docs: newArray,
        ...rest
      };
      let schedule = { ...state.schedule };
      if (schedule.id && schedule.id === action.payload.id) {
        schedule = scheduleInitialStatus;
      }
      return {
        ...state,
        schedule,
        schedules
      };
    }
    case LIST_EMPLOYEE_SCHEDULE: {
      const employeeSchedule = action.payload;
      return {
        ...state,
        employeeSchedule
      };
    }
    case RESET_PAYROLL_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}
