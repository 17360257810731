import { UserAuth } from 'src/app/feature/Users/models/User';
import { UsersRole } from 'src/app/core/config/enums';
import DefaultSidebar from './DefaultSidebar';
import HostSidebar from './HostSidebar';
import KitchenSidebar from './KitchenSidebar';
import PaymentSidebar from './PaymentSidebar';

const Sidebar = () => {
  const user: UserAuth = JSON.parse(localStorage.getItem('user'));
  switch (user.role) {
    case UsersRole.KITCHEN:
      return <KitchenSidebar />;
    case UsersRole.HOST:
      return <HostSidebar />;
    default:
      return user.allowedAccount.allowed ? (
        <DefaultSidebar />
      ) : (
        <PaymentSidebar />
      );
  }
};

export default Sidebar;
