import {
  ADD_FOOD_CATEGORY,
  DELETE_FOOD_CATEGORY,
  LIST_FOOD_CATEGORIES,
  TypesActionsFoodCategories,
  UPDATE_FOOD_CATEGORY,
  RESET_FOOD_CATEGORIES
} from './FoodCategoriesTypes';
import {
  FoodCategory,
  ResponseFoodCategoriesProps
} from 'src/app/feature/FoodCategories/models/FoodCategory';
import { FoodCategoriesDao } from 'src/app/core/api/foodCategories/dao/foodCategories.dao';
import { FoodCategoriesRepository } from 'src/app/core/api/foodCategories/repository/foodCategories.repository';
import Alert from 'src/app/shared/components/MultiplePorpuseAlert';
import { AxiosResponse } from 'axios';

export function listFoodCategories(
  payload: ResponseFoodCategoriesProps
): TypesActionsFoodCategories {
  return {
    type: LIST_FOOD_CATEGORIES,
    payload
  };
}

export function addNewFoodCategory(
  foodCategory: FoodCategory
): TypesActionsFoodCategories {
  return {
    type: ADD_FOOD_CATEGORY,
    payload: foodCategory
  };
}
export function updateFoodCategory(
  foodCategory: FoodCategory
): TypesActionsFoodCategories {
  return {
    type: UPDATE_FOOD_CATEGORY,
    payload: foodCategory
  };
}

export function deleteFoodCategory(
  foodCategory: string
): TypesActionsFoodCategories {
  return {
    type: DELETE_FOOD_CATEGORY,
    payload: foodCategory
  };
}

export function resetFoodCategories(): TypesActionsFoodCategories {
  return {
    type: RESET_FOOD_CATEGORIES
  };
}

export function listFoodCategoriesAsync(
  numberPage: number,
  limit: number,
  search: string | null
) {
  return async function (dispacth: Function) {
    await FoodCategoriesDao.list(numberPage, limit, search).then(
      (response: AxiosResponse<ResponseFoodCategoriesProps>) => {
        dispacth(listFoodCategories(response.data));
      }
    );
  };
}

export function deleteFoodCategoriesAsync(data: object) {
  return function (dispacth: Function) {
    FoodCategoriesRepository.delete(data).then(
      async (response: AxiosResponse<FoodCategory>) => {
        Alert({
          title: 'Hecho',
          text: `Categoría eliminada con éxito`,
          icon: 'success',
          timer: 3000
        });
        await FoodCategoriesDao.list(null, null, null).then(
          (response: AxiosResponse<ResponseFoodCategoriesProps>) => {
            dispacth(listFoodCategories(response.data));
          }
        );
      }
    );
  };
}

export function createFoodCategoriesAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<FoodCategory> =
        await FoodCategoriesRepository.create(form);
      dispacth(addNewFoodCategory(response.data));
      Alert({
        title: 'Hecho',
        text: `Categoría "${response.data.name}" creada con éxito`,
        icon: 'success',
        timer: 3000
      }).then(() => callback());
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      setValidate(true);
    }
  };
}

export function updateFoodCategoriesAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<FoodCategory> =
        await FoodCategoriesRepository.update(form);
      dispacth(updateFoodCategory(response.data));
      Alert({
        title: 'Hecho',
        text: `Categoria "${response.data.name}" actualizada con éxito`,
        icon: 'success',
        timer: 3000
      }).then(() => callback());
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      setValidate(true);
    }
  };
}

export function showFoodCategoriesAsync(id: string, callback: Function) {
  return function (dispacth: Function) {
    FoodCategoriesDao.show(id)
      .then((response: AxiosResponse<FoodCategory>) => {
        dispacth(addNewFoodCategory(response.data));
      })
      .catch(({ request }) => {
        Alert({
          title: 'Error',
          text: `${JSON.parse(request.response).message}`,
          icon: 'error'
        });
        callback();
      });
  };
}

export async function getAccountsListAsync() {
  return await FoodCategoriesDao.getAccountsList();
}

export async function getFoodCategoriesList(accountId: string) {
  const categories: AxiosResponse<FoodCategory[]> =
    await FoodCategoriesDao.getList(accountId);
  return categories;
}
