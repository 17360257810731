import { AxiosResponse } from 'axios';
import {
  UPDATE_ASSISTANCE,
  LIST_ASSISTANCES,
  ASSISTANCE_NOTIFICATIONS,
  CLEAR_ASSISTANCE_NOTIFICATIONS,
  RESET_ASSISTANCES_STATE,
  TypesActionsAssistance
} from './AssistanceTypes';
import {
  Assistance,
  ResponseAssistanceProps
} from 'src/app/feature/Assistance/models/Assistance';
import Alert from 'src/app/shared/components/MultiplePorpuseAlert';
import { AssistanceDao } from 'src/app/core/api/asisstance/dao/assistance.dao';
import { AssistanceRepository } from 'src/app/core/api/asisstance/repository/assistance.repository';

function listAssistance(
  payload: ResponseAssistanceProps
): TypesActionsAssistance {
  return {
    type: LIST_ASSISTANCES,
    payload
  };
}

export function listAssistanceNotifications(
  payload: Assistance[]
): TypesActionsAssistance {
  return {
    type: ASSISTANCE_NOTIFICATIONS,
    payload
  };
}

export function updateAssistance(account: string): TypesActionsAssistance {
  return {
    type: UPDATE_ASSISTANCE,
    payload: account
  };
}

export function clearAsistanceNotifications(): TypesActionsAssistance {
  return { type: CLEAR_ASSISTANCE_NOTIFICATIONS };
}

export function resetAssistancesState(): TypesActionsAssistance {
  return { type: RESET_ASSISTANCES_STATE };
}

export function listAssistanceAsync(
  numberPage: number,
  limit: number,
  account: string | null,
  search: string | null
) {
  return async function (dispacth: Function) {
    await AssistanceDao.list(numberPage, limit, account, search).then(
      (response: AxiosResponse<ResponseAssistanceProps>) => {
        dispacth(listAssistance(response.data));
      }
    );
  };
}

export function updateAssistanceAsync(id: string, account: string) {
  return function (dispacth: Function) {
    AssistanceRepository.delete(id).then(async (response) => {
      dispacth(updateAssistance(id));

      Alert({
        title: 'Hecho',
        text: `Cliente atendido.`,
        timer: 3000,
        icon: 'success'
      });
      await AssistanceDao.list(null, null, account, null).then(
        (response: AxiosResponse<ResponseAssistanceProps>) => {
          dispacth(listAssistance(response.data));
        }
      );
    });
  };
}
