import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  LIST_PRODUCTS,
  TypesActionsProducts,
} from '../../actions/products/ProductsActionsTypes';
import { ProductState } from '../../models/ProductState';
import { Product } from 'src/app/feature/Product/models/Product';

const initialState: ProductState = {
  products: Array<Product>(),
  countTotalProduct: 0,
};

export default function productReducer (
  state = initialState,
  action: TypesActionsProducts
): ProductState {
  switch (action.type) {
    case LIST_PRODUCTS: {
      const products = action.payload;
      return {
        ...state,
        products,
        countTotalProduct: action.countTotalProduct,
      };
    }
    case ADD_PRODUCT: {
      const product = action.payload;
      return {
        ...state,
        products: [...state.products, product],
      };
    }

    case DELETE_PRODUCT: {
      const product = action.payload;
      return {
        ...state,
        products: [
          ...state.products.filter((p) => p.title !== product.title),
        ],
      };
    }

    default:
      return state;
  }
}
