import * as Yup from 'yup';
import { UserAuth } from '../../Users/models/User';
import { Account, AccountSimplifiedPlan } from '../models/Accounts';
import { Plan } from '../../Common/models/Plan';

export interface ManagementAccountsDetailProps {
  getAccount: (id: string, callback: Function) => void;
  user: UserAuth;
  account: Account;
}

export interface ManagementAccountsEditProps {
  editAccount: (
    form: Object,
    callback: Function,
    setValidate: (value: boolean) => void
  ) => Promise<void>;
  getAccount: (id: string, callback: Function) => void;
  account: Account;
  user: UserAuth;
}

export interface AccountFormValues {
  name: string;
  documentId: string;
  country: string;
  state: string;
  city: string;
  address: string;
  zip: string;
  phone: string;
  email: string;
  representative: string;
  description: string;
  plan: string;
  planName: string;
  planPrice: number;
  planDescription: string;
  isEnable: boolean;
  lat: string;
  lon: string;
  status: string;
  image?: string;
  banner?: string;
  fileImage?: any;
  fileBanner?: any;
  id?: string;
  _id?: string;
}

export const initialValues: AccountFormValues = {
  name: '',
  documentId: '',
  country: '',
  state: '',
  city: '',
  address: '',
  zip: '',
  phone: '',
  email: '',
  representative: '',
  description: '',
  plan: '',
  planName: '',
  planPrice: 0,
  planDescription: '',
  isEnable: true,
  lat: '',
  lon: '',
  status: ''
};

export const validationSchema: Yup.SchemaOf<AccountFormValues> = Yup.object({
  name: Yup.string().required('Este campo es requerido'),
  documentId: Yup.string().required('Este campo es requerido'),
  country: Yup.string().required('Este campo es requerido'),
  state: Yup.string().required('Este campo es requerido'),
  city: Yup.string().required('Este campo es requerido'),
  address: Yup.string().required('Este campo es requerido'),
  zip: Yup.string().required('Este campo es requerido'),
  phone: Yup.string().required('Este campo es requerido'),
  email: Yup.string()
    .required('Este campo es requerido')
    .email('Debe introducir un correo electrónico válido'),
  representative: Yup.string().required('Este campo es requerido'),
  description: Yup.string().required('Este campo es requerido'),
  plan: Yup.string().required('Este campo es requerido'),
  planName: Yup.string(),
  planPrice: Yup.number(),
  planDescription: Yup.string(),
  isEnable: Yup.boolean().required('Este campo es requerido'),
  lat: Yup.string().required('Seleccione una ubicación en el mapa'),
  lon: Yup.string().required('Seleccione una ubicación en el mapa'),
  status: Yup.string().required('Este campo es requerido'),
  image: Yup.string(),
  banner: Yup.string(),
  fileImage: Yup.object().nullable(),
  fileBanner: Yup.object().nullable(),
  id: Yup.string(),
  _id: Yup.string()
});

export const PlanInitialValues: Plan = {
  _id: '',
  name: '',
  description: '',
  active: false,
  price: 0,
  rules: {},
  setupFee: 0
};

export const AccountInitialValues: Account = {
  _id: '',
  name: '',
  documentId: '',
  country: '',
  state: '',
  city: '',
  address: '',
  zip: '',
  phone: '',
  email: '',
  representative: '',
  description: '',
  plan: PlanInitialValues,
  isEnable: false,
  lat: '',
  lon: '',
  status: '',
  image: '',
  banner: '',
  createdAt: '',
  feeServicesQuota: []
};

export const AccountSimplifiedPlanValues: AccountSimplifiedPlan = {
  _id: '',
  name: '',
  documentId: '',
  country: '',
  state: '',
  city: '',
  address: '',
  zip: '',
  phone: '',
  email: '',
  representative: '',
  description: '',
  plan: '',
  isEnable: false,
  lat: '',
  lon: '',
  status: '',
  image: '',
  banner: '',
  createdAt: '',
  feeServicesQuota: []
};
