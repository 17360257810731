import { toast, TypeOptions, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ToastifyAlertProps {
  text?: string;
  timer?: number;
  type?: TypeOptions;
  position?: ToastPosition;
  hideProgressBar?: boolean;
}

const ToastifyAlert = ({
  text = 'Hecho',
  timer = 3000,
  type = 'success',
  position = 'top-right',
  hideProgressBar = false
}: ToastifyAlertProps) => {
  return toast(text, {
    type,
    position,
    autoClose: timer,
    hideProgressBar,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: 'light'
  });
};

export default ToastifyAlert;
