import { Suspense, lazy } from 'react';
import SidebarLayout from 'src/app/shared/layouts/SidebarLayout';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';
import BaseLayout from 'src/app/shared/layouts/BaseLayout';
import SuspenseLoader from 'src/app/shared/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Status404 = Loader(
  lazy(() => import('src/app/shared/content/pages/Status/Status404'))
);

// Orders

const Orders = Loader(
  lazy(() => import('src/app/feature/OrdersHost/Main/pages/Main'))
);
const OrdersDetail = Loader(
  lazy(() => import('src/app/feature/Orders/Detail/pages/Main'))
);
const OrdersCreate = Loader(
  lazy(() => import('src/app/feature/Orders/Create/pages/Main'))
  // Reportes
);

//Profile

const Profile = Loader(
  lazy(() => import('src/app/feature/ProfileEdit/pages/ProfileEditPage'))
);

const Assistance = Loader(
  lazy(() => import('src/app/feature/Assistance/Main/pages/Main'))
);

const routesProtectedHost: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/orders" replace />
      },
      {
        path: '/dashboard',
        element: <Navigate to="/orders" replace />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: '/orders',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Orders />
      },
      {
        path: '/:idOrder',
        element: <OrdersDetail />
      },
      {
        path: '/create',
        element: <OrdersCreate />
      }
    ]
  },
  {
    path: 'profile',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Profile />
      }
    ]
  },
  {
    path: 'assistance',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Assistance />
      }
    ]
  }
];

export default routesProtectedHost;
