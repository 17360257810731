import { axiosIntance } from 'src/app/core/config/AxiosConfig';

export const TablesRepository = {
  delete: (data: any) => {
    return axiosIntance.delete(`/tables`, { data: { id:data._id,...data } });
  },
  create: (form: object) => {
    return axiosIntance.post(`/tables`, form);
  },
  update: (form: object) => {
    return axiosIntance.put(`/tables`, form);
  }
};
