import { AxiosResponse } from 'axios';

import { axiosIntance } from 'src/app/core/config/AxiosConfig';
import { Setting } from 'src/app/feature/Settings/models/Setting';

export const SettingsRepository = {
  create: (form: object): Promise<AxiosResponse<Setting>> => {
    return axiosIntance.post('/settings', form);
  },
  update: (form: object): Promise<AxiosResponse<Setting>> => {
    return axiosIntance.put('/settings', form);
  },
  upload: (form: object): Promise<AxiosResponse<Setting>> => {
    return axiosIntance.post('/settings/upload-p12', form);
  },
  notifications: (form: object): Promise<AxiosResponse<Setting>> => {
    return axiosIntance.put('/settings/notifications', form);
  }
};
