import {
  Account,
  ResponseAccountsProps
} from 'src/app/feature/Accounts/models/Accounts';

export const LIST_ACCOUNTS = 'LIST_ACCOUNTS';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const RESET_ACCOUNTS_STATE = 'RESET_ACCOUNTS_STATE';

interface ActionListAccounts {
  type: typeof LIST_ACCOUNTS;
  payload: ResponseAccountsProps;
}

interface ActionAddAccounts {
  type: typeof ADD_ACCOUNT;
  payload: Account;
}

interface ActionDeleteAccounts {
  type: typeof DELETE_ACCOUNT;
  payload: string;
}

interface ActionResetAccountsState {
  type: typeof RESET_ACCOUNTS_STATE;
}

export type TypesActionsAccounts =
  | ActionListAccounts
  | ActionAddAccounts
  | ActionDeleteAccounts
  | ActionResetAccountsState;
