import { useNavigate } from 'react-router';
import { Box } from '@mui/material';

import HeaderNotifications from './Notifications';
import BaseButton from 'src/app/shared/components/Buttons/BaseButton';
import { UserAuth } from 'src/app/feature/Users/models/User';
import { UsersRole } from 'src/app/core/config/enums';
import styles from './styles.module.scss';

function HeaderButtons() {
  const user: UserAuth = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  return (
    <Box sx={{ mr: 1, display: 'flex', hover: 'black' }}>
      {user.role === UsersRole.KITCHEN ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <BaseButton
            btnText="Historial"
            size="small"
            onClick={() => navigate('/orders')}
            className={styles.primaryButton}
            extraSx={{ padding: 'none', fontSize: '0.75rem' }}
            toolTip="Ir a Historial"
          />
        </Box>
      ) : (
        ''
      )}
      <Box sx={{ mx: 0.5 }} component="span">
        {user.allowedAccount.allowed ? <HeaderNotifications /> : ''}
      </Box>
    </Box>
  );
}

export default HeaderButtons;
