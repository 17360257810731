import { AxiosResponse } from 'axios';
import { GET_DASHBOARD, RESET_DASHBOARD, TypesActionsDashboard } from './DashboardTypes';
import { Dashboard } from 'src/app/feature/DashBoard/models/DashBoard';
import { DashboardDao } from 'src/app/core/api/dashboard/dashboard.dao';
import Alert from 'src/app/shared/components/MultiplePorpuseAlert';

export function getDashboard(payload: Dashboard): TypesActionsDashboard {
  return {
    type: GET_DASHBOARD,
    payload
  };
}

export function resetDashboard(): TypesActionsDashboard {
  return {
    type: RESET_DASHBOARD
  };
}

export function getDashboardAsync(accountId: string) {
  return async function (dispacth: Function) {
    try {
      const dashboard: AxiosResponse<Dashboard> = await DashboardDao.get(
        accountId
      );
      dispacth(getDashboard(dashboard.data));
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
    }
  };
}
