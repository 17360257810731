import {
  ADD_EMPLOYEE,
  LIST_EMPLOYEES,
  UPDATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  ADD_SCHEDULE,
  LIST_EMPLOYEE_SCHEDULE,
  LIST_SCHEDULE,
  RESET_PAYROLL_STATE,
  TypesActionsPayroll
} from './PayrollTypes';
import {
  DataEmployeeId,
  Employee,
  ResponseEmployee,
  ResponseSchedule,
  EmployeeSchedule,
  ScheduleItem
} from 'src/app/feature/Employees/models/Employee';
import { PayrollDao } from 'src/app/core/api/payroll/dao/payroll.dao';
import { PayrollRepository } from 'src/app/core/api/payroll/repository/payroll.repository';
import Alert from 'src/app/shared/components/MultiplePorpuseAlert';
import ToastifyAlert from 'src/app/shared/components/MultiplePorpuseAlert/ToastifyAlert';

function listEmployees(payload: ResponseEmployee): TypesActionsPayroll {
  return {
    type: LIST_EMPLOYEES,
    payload
  };
}

function listEmployeeSchedule(payload: ResponseSchedule): TypesActionsPayroll {
  return {
    type: LIST_EMPLOYEE_SCHEDULE,
    payload
  };
}

function listSchedule(payload: ResponseSchedule): TypesActionsPayroll {
  return {
    type: LIST_SCHEDULE,
    payload
  };
}

function addSchedule(payload: EmployeeSchedule): TypesActionsPayroll {
  return {
    type: ADD_SCHEDULE,
    payload
  };
}

function addEmployee(payload: Employee): TypesActionsPayroll {
  return {
    type: ADD_EMPLOYEE,
    payload
  };
}
function updateEmployee(payload: DataEmployeeId): TypesActionsPayroll {
  return {
    type: UPDATE_EMPLOYEE,
    payload
  };
}

function deleteEmployee(payload: Employee): TypesActionsPayroll {
  return {
    type: DELETE_EMPLOYEE,
    payload
  };
}

export function resetPayrollState(): TypesActionsPayroll {
  return {
    type: RESET_PAYROLL_STATE
  };
}

export function listEmployeesAsync(
  page: number,
  limit: number,
  accountId: string,
  search: string | null,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    try {
      const response = await PayrollDao.listEmployees(
        accountId,
        page,
        limit,
        search
      );
      dispacth(listEmployees(response.data));
      callbackSuccess &&
        typeof callbackSuccess === 'function' &&
        callbackSuccess();
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      callbackError && typeof callbackError === 'function' && callbackError();
    }
  };
}

export function deleteEmployeeAsync(
  accountId: string,
  employee: Employee,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    try {
      await PayrollRepository.deleteEmployee({
        accountId,
        id: employee.dataEmployeeId?._id
      });
      dispacth(deleteEmployee(employee));
      callbackSuccess &&
        typeof callbackSuccess === 'function' &&
        callbackSuccess();
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      callbackError && typeof callbackError === 'function' && callbackError();
    }
  };
}

export function createEmployeeAsync(
  form: object,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    try {
      const response = await PayrollRepository.createEmployee(form);
      dispacth(updateEmployee(response.data));
      Alert({
        text: 'Datos de empleo agregados',
        timer: 3000
      }).then(
        () =>
          callbackSuccess &&
          typeof callbackSuccess === 'function' &&
          callbackSuccess()
      );
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      callbackError && typeof callbackError === 'function' && callbackError();
    }
  };
}

export function updateEmployeeAsync(
  form: object,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    try {
      const response = await PayrollRepository.updateEmployee(form);
      dispacth(updateEmployee(response.data));
      Alert({
        text: 'Datos de empleo actualizados',
        timer: 3000
      }).then(
        () =>
          callbackSuccess &&
          typeof callbackSuccess === 'function' &&
          callbackSuccess()
      );
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      callbackError && typeof callbackError === 'function' && callbackError();
    }
  };
}

export function getEmployeeAsync(
  accountId: string,
  userId: string,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    try {
      const response = await PayrollDao.getEmployee(accountId, userId);
      dispacth(addEmployee(response.data));
      callbackSuccess &&
        typeof callbackSuccess === 'function' &&
        callbackSuccess();
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      callbackError && typeof callbackError === 'function' && callbackError();
    }
  };
}

export function listEmployeeScheduleAsync(
  page: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    try {
      const response = await PayrollDao.listSchedules(
        accountId,
        page,
        limit,
        search,
        filter
      );
      dispacth(listEmployeeSchedule(response.data));
      callbackSuccess &&
        typeof callbackSuccess === 'function' &&
        callbackSuccess();
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      callbackError && typeof callbackError === 'function' && callbackError();
    }
  };
}

export function listScheduleAsync(
  page: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    try {
      const response = await PayrollDao.listSchedules(
        accountId,
        page,
        limit,
        search,
        filter
      );
      dispacth(listSchedule(response.data));
      callbackSuccess &&
        typeof callbackSuccess === 'function' &&
        callbackSuccess();
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      callbackError && typeof callbackError === 'function' && callbackError();
    }
  };
}

export function addScheduleAsync(
  form: object,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    try {
      const response = await PayrollRepository.createSchedule(form);
      dispacth(addSchedule(response.data));
      ToastifyAlert({
        type: 'success',
        text: 'Horario agregado con éxito'
      });
      callbackSuccess &&
        typeof callbackSuccess === 'function' &&
        callbackSuccess();
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      callbackError && typeof callbackError === 'function' && callbackError();
    }
  };
}

export function deleteScheduleAsync(
  accountId: string,
  schedule: ScheduleItem,
  callbackSuccess?: Function,
  callbackError?: Function
) {
  return async function (dispacth: Function) {
    try {
      await PayrollRepository.deleteSchedule({
        accountId,
        id: schedule._id
      });
      callbackSuccess &&
        typeof callbackSuccess === 'function' &&
        callbackSuccess();
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      callbackError && typeof callbackError === 'function' && callbackError();
    }
  };
}

export async function getEmployeesExcel(
  accountId: string,
  search: string | null,
  filter: string | null
) {
  try {
    return await PayrollDao.getExcel(accountId, search, filter);
  } catch ({ request }) {
    Alert({
      title: 'Error',
      text: `${JSON.parse(request.response).message}`,
      icon: 'error'
    });
  }
}
