import { UserAuth } from 'src/app/feature/Users/models/User';

export const ADD_USER_AUTH = 'ADD_USER_AUTH';
export const CHANGE_ACCOUNT = 'CHANGE_ACCOUNT';
export const UPDATE_USER_AUTH = 'UPDATE_USER_AUTH';
export const CLEAR_USER_AUTH = 'CLEAR_USER_AUTH';

interface AccionAgregarUser {
  type: typeof ADD_USER_AUTH;
  payload: UserAuth;
  accessToken: string;
}

interface ActionChangeAccount {
  type: typeof CHANGE_ACCOUNT;
  payload: UserAuth;
}

interface ActionUpdateUserAuth {
  type: typeof UPDATE_USER_AUTH;
  payload: UserAuth;
}

interface ActionClearUserAuth {
  type: typeof CLEAR_USER_AUTH;
}

export type TypesActionsUser =
  | AccionAgregarUser
  | ActionChangeAccount
  | ActionUpdateUserAuth
  | ActionClearUserAuth;
