import { AxiosResponse } from 'axios';

import { axiosIntance } from 'src/app/core/config/AxiosConfig';
import {
  Setting,
  PermissionsPos
} from 'src/app/feature/Settings/models/Setting';

export const SettingsDao = {
  list: (accountId: string): Promise<AxiosResponse<Setting[]>> => {
    return axiosIntance.get(`/settings/list?account=${accountId}`);
  },
  gerPermissionList: (): Promise<AxiosResponse<PermissionsPos>> => {
    return axiosIntance.get(`/settings/permissions`);
  }
};
