import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { themeCreator } from './base';
import { StylesProvider } from '@mui/styles';
import { Helmet } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';

export const ThemeContext = React.createContext(
  (themeName: string): void => {}
);

const ThemeProviderWrapper: React.FC = (props) => {
  const [curThemeName] = useState(
    localStorage.getItem('appTheme') || 'PureLightTheme'
  );
  useEffect(() => {}, [curThemeName]);

  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = (themeName: string): void => {
    _setThemeName(themeName);
  };
  const [stylePath] = useState(
    'https://fonts.googleapis.com/icon?family=Material+Icons'
  );

  return (
    <StylesProvider injectFirst>
      <Helmet>
        <link rel="stylesheet" type="text/css" href={stylePath} />
      </Helmet>
      {/*Añadimos el ToastContainer para que este disponible globalmente..*/}
      <ToastContainer />
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
