export enum CategoryTypes {
  DEFAULT = 'default',
  CUSTOM = 'custom'
}
export enum CategoryStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum CategoryStatusSpanish {
  ACTIVE = 'activo',
  INACTIVE = 'inactivo'
}

export enum UsersStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum UsersStatusSpanish {
  ACTIVE = 'activo',
  INACTIVE = 'inactivo'
}

export enum EmployeeEnum {
  ACTIVE = 'Activo',
  INACTIVE = 'Inactivo'
}

export enum UsersRole {
  SUPERADMIN = 'superadmin',
  ADMIN = 'admin',
  OWNER = 'owner',
  MANAGER = 'manager',
  KITCHEN = 'kitchen',
  HOST = 'host'
}

export enum ComplementsStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum ComplementsStatusSpanish {
  ACTIVE = 'activo',
  INACTIVE = 'inactivo'
}

export enum AccountStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum AccountStatusEnumSpanish {
  ACTIVE = 'ACTIVO',
  INACTIVE = 'INACTIVO'
}

export enum IsEnableEnum {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE'
}

export enum IsEnableEnumSpanish {
  ENABLE = 'HABILITADO',
  DISABLE = 'INHABILITADO'
}

export enum IsEnableTableEnum {
  ENABLE = 'ACTIVE',
  DISABLE = 'INACTIVE'
}

export enum IsEnableTableEnumSpanish {
  ENABLE = 'ACTIVO',
  DISABLE = 'INACTIVO'
}

export enum PaymentTypes {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE'
}

export enum StatusOrder {
  GENERATED = 'Generada',
  INPREPARATION = 'En preparación',
  COMPLETED = 'Completada',
  CANCELLED = 'Anulada',
  CHANGE_FOOD_STATUS = 'Entregado'
}

export enum DateSelectorEnum {
  Today = 'TODAY',
  LastSevenDays = 'LASTSEVENDAYS',
  Range = 'RANGE',
  Month = 'MONTH',
  LastMonth = 'LASTMONTH',
  Year = 'YEAR'
}

export enum ReportSelectorEnum {
  orders = 'orders',
  sales = 'sales'
}

export enum PaymentOrderStatus {
  PENDING = 'Pendiente',
  COMPLETED = 'Completada'
}

export enum RefreshIntervals {
  TENSECONDS = '10000',
  THIRTYSECONDS = '30000',
  SIXTYSECONDS = '60000'
}

export enum OrderMaxTimes {
  FIVEMINUTES = '300000',
  TENMINUTES = '600000',
  FIXTEENMINUTES = '900000',
  TWENTYMINUTES = '1200000',
  TWENTYFIVEMINUTES = '1500000',
  THIRTYMINUTES = '1800000',
  THIRTYFIVEMINUTES = '2100000',
  FORTYMINUTES = '2400000',
  FORTYFIVEMINUTES = '2700000',
  FIXTYMINUTES = '3000000',
  FIXTYFIVEMINUTES = '3300000',
  SIXTYMINUTES = '3600000'
}

export enum CustomFilterTypes {
  SELECT = 'select',
  INPUT_TEXT = 'input_text',
  INPUT_DATE = 'input_DATE',
  MULTI_SELECT = 'multi_select'
}

export enum sectionsFilters {
  ORDERS = 'orders'
}

export enum InvoiceStatusEnum {
  PAID = 'paid',
  PENDING = 'pending'
}

export enum InvoiceStatusEnumSpanish {
  PAID = 'pagado',
  PENDING = 'pendiente'
}

export enum TypeDocumentIdSpanish {
  CI = 'física',
  CJ = 'jurídica'
}

export enum ElectronicInvoice {
  pending = 'pending',
  generated = 'generated'
}

export enum ElectronicInvoiceSpanish {
  pending = 'pendiente',
  generated = 'generada'
}

export enum ElectronicInvoiceOptions {
  all = 'all',
  pending = 'pending',
  generated = 'generated'
}

export enum ElectronicInvoiceOptionsSpanish {
  all = 'todas',
  pending = 'pendiente',
  generated = 'generada'
}

export enum SettingTypes {
  KITCHEN = 'kitchen'
}

export enum OrderTypes {
  INSITE = 'en el lugar',
  DELIVERY = 'delivery',
  TOGO = 'para llevar',
  ANTICIPADE = 'anticipado'
}

export enum RegimenTypes {
  SIMPLIFIED = 'Simplificado',
  TRADITIONAL = 'Tradicional'
}

export enum PermissionsPosEnum {
  GENERAL = 'general',
  SUPERVISOR = 'supervisor',
  BILL = 'bill',
  INVENTORY = 'inventory',
  OPERATIONS = 'operations',
  ADMINISTRATION = 'administration',
  PAYROLL = 'payroll'
}

export enum PermissionsPosSpanishEnum {
  GENERAL = 'General',
  SUPERVISOR = 'Supervisor',
  BILL = 'Facturar',
  INVENTORY = 'Inventario',
  OPERATIONS = 'Operaciones',
  ADMINISTRATION = 'Administración',
  PAYROLL = 'Planilla'
}

export enum SettingNotificationEnum {
  FULL = 'full',
  PARTIAL = 'partial'
}

export enum TabEmployeesEnum {
  GENERAL = 'general',
  PRIVATE = 'private',
  SCHEDULE = 'schedule'
}

export enum AttendanceType {
  IN = 'in',
  OUT = 'out',
  BREAK = 'break'
}

export enum AttendanceTypeSpanish {
  IN = 'Entrada',
  OUT = 'Salida',
  BREAK = 'Descanso'
}

export enum SpanishDays {
  monday = 'Lunes',
  tuesday = 'Martes',
  wednesday = 'Miércoles',
  thursday = 'Jueves',
  friday = 'Viernes',
  saturday = 'Sábado',
  sunday = 'Domingo'
}
