import { axiosIntance } from '../../../config/AxiosConfig';

export const AssistanceDao = {
  list: (
    page: number | string | null,
    limit: number | string | null,
    account: string | null,
    search: string | null,
  ) => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (account) {
      account = `&account=${account}`;
    }
    if (!page) {
      page = process.env.REACT_APP_LIST_PAGE_DEFAULT;
    }
    if (!limit) {
      limit = process.env.REACT_APP_LIST_ROW_DEFAULT;
    }
    return axiosIntance.get(`/assistance?page=${page}&limit=${limit}${query}${account}`);
  },  
};
