import { AxiosResponse } from 'axios';
import {
  ADD_ACCOUNT,
  DELETE_ACCOUNT,
  LIST_ACCOUNTS,
  RESET_ACCOUNTS_STATE,
  TypesActionsAccounts
} from './AccountsTypes';
import {
  Account,
  ResponseAccountsProps
} from 'src/app/feature/Accounts/models/Accounts';
import { AccountsDao } from 'src/app/core/api/accounts/dao/accounts.dao';
import { AccountsRepository } from 'src/app/core/api/accounts/repository/accounts.repository';
import Alert from 'src/app/shared/components/MultiplePorpuseAlert';
import { UserAuth } from 'src/app/feature/Users/models/User';

function listAccounts(payload: ResponseAccountsProps): TypesActionsAccounts {
  return {
    type: LIST_ACCOUNTS,
    payload
  };
}

function addNewAccount(account: Account): TypesActionsAccounts {
  return {
    type: ADD_ACCOUNT,
    payload: account
  };
}

export function deleteAccount(account: string): TypesActionsAccounts {
  return {
    type: DELETE_ACCOUNT,
    payload: account
  };
}

export function resetAccountsState(): TypesActionsAccounts {
  return {
    type: RESET_ACCOUNTS_STATE
  };
}

export function listAccountsAsync(
  numberPage: number,
  limit: number,
  search: string | null
) {
  return async function (dispacth: Function) {
    await AccountsDao.list(numberPage, limit, search).then(
      (response: AxiosResponse<ResponseAccountsProps>) => {
        dispacth(listAccounts(response.data));
      }
    );
  };
}

export function deleteAccountsAsync(data: object) {
  return function (dispacth: Function) {
    AccountsRepository.delete(data).then(async (response) => {
      Alert({
        title: 'Hecho',
        text: `Restaurante eliminado con éxito`,
        timer: 3000,
        icon: 'success'
      });
      await AccountsDao.list(null, null, null).then(
        (response: AxiosResponse<ResponseAccountsProps>) => {
          dispacth(listAccounts(response.data));
        }
      );
    });
  };
}

export function showAccountsAsync(id: string, callback: Function) {
  return function (dispacth: Function) {
    AccountsDao.show(id)
      .then((response: AxiosResponse<Account>) => {
        dispacth(addNewAccount(response.data));
      })
      .catch(({ request }) => {
        Alert({
          title: 'Error',
          text: `${JSON.parse(request.response).message}`,
          icon: 'error'
        });
        callback();
      });
  };
}

export const createAccountAsync = (
  form: Object,
  callback: Function,
  setValidate: Function
) => {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<Account> = await AccountsRepository.create(
        form
      );
      dispacth(addNewAccount(response.data));
      Alert({
        title: 'Hecho',
        text: `Restaurante creado con éxito`,
        icon: 'success',
        timer: 3000
      }).then(() => callback());
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      setValidate(true);
    }
  };
};

export const editAccountAsync = (
  form: Account,
  callback: Function,
  setValidate: (value: boolean) => void
) => {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<Account> = await AccountsRepository.update(
        form
      );
      const accountUpdate: UserAuth = JSON.parse(localStorage.getItem('user'));
      const foundIndex = accountUpdate.account.findIndex(
        (x) => x.id === form.id
      );
      accountUpdate.account[foundIndex] = form;
      localStorage.setItem('user', JSON.stringify(accountUpdate));
      dispacth(addNewAccount(response.data));
      Alert({
        title: 'Hecho',
        text: `Restaurante actualizado con éxito`,
        icon: 'success',
        timer: 3000
      }).then(() => callback());
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      setValidate(true);
    }
  };
};
