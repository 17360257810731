import {
  DataEmployeeId,
  ResponseEmployee,
  Employee,
  ResponseSchedule,
  EmployeeSchedule
} from 'src/app/feature/Employees/models/Employee';

export const LIST_EMPLOYEES = 'LIST_EMPLOYEES';
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const LIST_SCHEDULE = 'LIST_SCHEDULE';
export const ADD_SCHEDULE = 'ADD_SCHEDULE';
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';
export const LIST_EMPLOYEE_SCHEDULE = 'LIST_EMPLOYEE_SCHEDULE';
export const RESET_PAYROLL_STATE = 'RESET_PAYROLL_STATE';

interface ActionListEmployees {
  type: typeof LIST_EMPLOYEES;
  payload: ResponseEmployee;
}

interface ActionAddEmployees {
  type: typeof ADD_EMPLOYEE;
  payload: Employee;
}

interface ActionDeleteEmployees {
  type: typeof DELETE_EMPLOYEE;
  payload: Employee;
}

interface ActionUpdateEmployees {
  type: typeof UPDATE_EMPLOYEE;
  payload: DataEmployeeId;
}

interface ActionListSchedule {
  type: typeof LIST_SCHEDULE;
  payload: ResponseSchedule;
}

interface ActionAddSchedule {
  type: typeof ADD_SCHEDULE;
  payload: EmployeeSchedule;
}

interface ActionDeleteSchedule {
  type: typeof DELETE_SCHEDULE;
  payload: EmployeeSchedule;
}

interface ActionListEmployeeSchedule {
  type: typeof LIST_EMPLOYEE_SCHEDULE;
  payload: ResponseSchedule;
}

interface ActionResetPayrollState {
  type: typeof RESET_PAYROLL_STATE;
}

export type TypesActionsPayroll =
  | ActionListEmployees
  | ActionAddEmployees
  | ActionDeleteEmployees
  | ActionUpdateEmployees
  | ActionListSchedule
  | ActionAddSchedule
  | ActionDeleteSchedule
  | ActionListEmployeeSchedule
  | ActionResetPayrollState;
