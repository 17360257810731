import { axiosIntance } from 'src/app/core/config/AxiosConfig';

export const AssistanceRepository = {
  delete: (id: string) => {
    return axiosIntance.post(`/assistance/finish`, {
        id
    });
  },
 
};
