import {
  UPDATE_ASSISTANCE,
  LIST_ASSISTANCES,
  ASSISTANCE_NOTIFICATIONS,
  CLEAR_ASSISTANCE_NOTIFICATIONS,
  RESET_ASSISTANCES_STATE,
  TypesActionsAssistance
} from '../../actions/assistance/AssistanceTypes';
import { AssistanceState } from '../../models/assistance/AssistanceState';
import { generalResponse } from 'src/app/feature/Common/models/GeneralResponse';

const initialState: AssistanceState = {
  assistances: generalResponse,
  assistance: null,
  assistanceNotifications: []
};

export default function assistanceReducer(
  state = initialState,
  action: TypesActionsAssistance
) {
  switch (action.type) {
    case LIST_ASSISTANCES: {
      const assistances = action.payload;
      return {
        ...state,
        assistances
      };
    }
    case UPDATE_ASSISTANCE: {
      const assistance = action.payload;
      return {
        ...state,
        assistances: {
          ...state.assistances,
          docs: [...state.assistances.docs.filter((e) => e._id !== assistance)],
          totalDocs: state.assistances.totalDocs - 1
        }
      };
    }
    case ASSISTANCE_NOTIFICATIONS: {
      const assistanceNotifications = action.payload;
      return {
        ...state,
        assistanceNotifications
      };
    }
    case CLEAR_ASSISTANCE_NOTIFICATIONS: {
      return {
        ...state,
        assistanceNotifications: []
      };
    }
    case RESET_ASSISTANCES_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}
