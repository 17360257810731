import { useContext } from 'react';
import { Box, Hidden, IconButton, Tooltip, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/app/shared/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import AccountName from './AccountName';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${
              localStorage.getItem('appTheme') === 'PureLightThemeKitchen'
                ? '0px'
                : theme.sidebar.width
            };
            width: auto;
        }
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Grid container item xs={8}>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center">
            <Hidden smUp>
              <HeaderUserbox />
            </Hidden>
            <Hidden smDown>
              <AccountName />
            </Hidden>
          </Box>
        </Grid>
      </Grid>
      <Box display="flex" alignItems="center">
        <HeaderButtons />
        <Hidden smDown>
          <HeaderUserbox />
        </Hidden>
        <Tooltip arrow title="Toggle Menu">
          <IconButton color="secondary" onClick={toggleSidebar}>
            {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
          </IconButton>
        </Tooltip>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
