import { AxiosResponse } from 'axios';

import {
  ADD_TABLE,
  DELETE_TABLE,
  LIST_TABLES,
  TypesActionsTables,
  UPDATE_TABLE,
  RESET_TABLES
} from './TablesTypes';
import {
  Table,
  ResponseTablesProps
} from 'src/app/feature/Tables/models/Tables';
import { TablesDao } from 'src/app/core/api/tables/dao/tables.dao';
import { TablesRepository } from 'src/app/core/api/tables/repository/tables.repository';
import Alert from 'src/app/shared/components/MultiplePorpuseAlert';

export function listTables(payload: ResponseTablesProps): TypesActionsTables {
  return {
    type: LIST_TABLES,
    payload
  };
}

export function addNewTable(Table: Table): TypesActionsTables {
  return {
    type: ADD_TABLE,
    payload: Table
  };
}
export function updateTable(Table: Table): TypesActionsTables {
  return {
    type: UPDATE_TABLE,
    payload: Table
  };
}

export function deleteTable(Table: string): TypesActionsTables {
  return {
    type: DELETE_TABLE,
    payload: Table
  };
}

export function resetTables(): TypesActionsTables {
  return {
    type: RESET_TABLES
  };
}

export function listTablesAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null
) {
  return async function (dispacth: Function) {
    await TablesDao.list(accountId, numberPage, limit, search).then(
      (response: AxiosResponse<ResponseTablesProps>) => {
        dispacth(listTables(response.data));
      }
    );
  };
}

export function deleteTablesAsync(accountId: string, data: object) {
  return function (dispacth: Function) {
    TablesRepository.delete(data).then(async (response: any) => {
      Alert({ text: `Mesa eliminada con éxito`, timer: 3000 });
      await TablesDao.list(accountId, null, null, null).then(
        (response: AxiosResponse<ResponseTablesProps>) => {
          dispacth(listTables(response.data));
        }
      );
    });
  };
}

export function createTablesAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<Table> = await TablesRepository.create(
        form
      );
      dispacth(addNewTable(response.data));
      Alert({ text: `Mesa creada con éxito`, timer: 3000 }).then(() =>
        callback()
      );
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      setValidate(true);
    }
  };
}

export function updateTablesAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<Table> = await TablesRepository.update(
        form
      );
      dispacth(updateTable(response.data));
      Alert({ text: `Mesa actualizada con éxito`, timer: 3000 }).then(() =>
        callback()
      );
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      setValidate(true);
    }
  };
}

export function showTablesAsync(id: string, callback: Function) {
  return function (dispacth: Function) {
    TablesDao.show(id)
      .then((response: any) => {
        dispacth(addNewTable(response.data));
      })
      .catch(({ request }) => {
        Alert({
          title: 'Error',
          text: `${JSON.parse(request.response).message}`,
          icon: 'error'
        });
        callback();
      });
  };
}
