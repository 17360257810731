import {
  GET_ORDERS_GRAPH,
  GET_ORDERS_BY_FOOD,
  GET_ORDERS_BY_STATUS,
  GET_ORDERS_TRACKING,
  GET_SALES_BY_ACCOUNT,
  GET_SALES_BY_FOOD,
  GET_SALES_PER_MONTH,
  RESET_REPORTS,
  GET_SALES_REPORT,
  GET_INVENTORY_REPORT,
  GET_PAYROLL_REPORT,
  TypesActionsReport
} from '../../actions/reports/ReportTypes';
import { ReportsState } from '../../models/reports/ReportsState';
import { generalResponse } from 'src/app/feature/Common/models/GeneralResponse';
import {
  salesReportInitialState,
  inventoryReportInitialState,
  payrollReportInitialState
} from 'src/app/feature/Report/Interfaces/interfaces';

const initialState: ReportsState = {
  ordersGraph: [],
  ordersByFood: [],
  ordersByStatus: [],
  ordersTracking: generalResponse,
  salesByAccount: [],
  salesByFood: generalResponse,
  salesPerMonth: { data: [] },
  salesReport: salesReportInitialState,
  inventoryReport: inventoryReportInitialState,
  payrollReport: payrollReportInitialState
};

export default function reportsReducer(
  state = initialState,
  action: TypesActionsReport
) {
  switch (action.type) {
    case GET_ORDERS_GRAPH: {
      const ordersGraph = action.payload;
      return {
        ...state,
        ordersGraph
      };
    }
    case GET_ORDERS_BY_STATUS: {
      const ordersByStatus = action.payload;
      return {
        ...state,
        ordersByStatus
      };
    }
    case GET_ORDERS_BY_FOOD: {
      const ordersByFood = action.payload;
      return {
        ...state,
        ordersByFood
      };
    }
    case GET_ORDERS_TRACKING: {
      const ordersTracking = action.payload;
      return {
        ...state,
        ordersTracking
      };
    }
    case GET_SALES_BY_ACCOUNT: {
      const salesByAccount = action.payload;
      return {
        ...state,
        salesByAccount
      };
    }
    case GET_SALES_BY_FOOD: {
      const salesByFood = action.payload;
      return {
        ...state,
        salesByFood
      };
    }
    case GET_SALES_PER_MONTH: {
      const salesPerMonth = action.payload;
      return {
        ...state,
        salesPerMonth
      };
    }
    case GET_SALES_REPORT: {
      const salesReport = action.payload;
      return {
        ...state,
        salesReport
      };
    }
    case GET_INVENTORY_REPORT: {
      const inventoryReport = action.payload;
      return {
        ...state,
        inventoryReport
      };
    }
    case GET_PAYROLL_REPORT: {
      const payrollReport = action.payload;
      return {
        ...state,
        payrollReport
      };
    }
    case RESET_REPORTS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
