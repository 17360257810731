import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';
import HeaderKitchen from './Header/kitchen';
import { UserAuth } from 'src/app/feature/Users/models/User';
import { UsersRole } from 'src/app/core/config/enums';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const MainWrapper = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: ${
              localStorage.getItem('appTheme') === 'PureLightThemeKitchen'
                ? '0px'
                : theme.sidebar.width
            };
        }
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
        background-color: #efcb9126;
`
);

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  let HeaderComponent = <Header />;
  if (localStorage.getItem('user')) {
    const user: UserAuth = JSON.parse(localStorage.getItem('user'));
    if (user.role === UsersRole.KITCHEN) {
      HeaderComponent = <HeaderKitchen />;
    }
  }
  return (
    <>
      <Sidebar />
      <MainWrapper>
        {HeaderComponent}
        <MainContent>
          <Outlet />
        </MainContent>
      </MainWrapper>
    </>
  );
};

export default SidebarLayout;
