import { Suspense, lazy } from 'react';
import { PartialRouteObject } from 'react-router';
import BaseLayout from 'src/app/shared/layouts/BaseLayout';
import SuspenseLoader from 'src/app/shared/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
const Status404 = Loader(
  lazy(() => import('src/app/shared/content/pages/Status/Status404'))
);
const Login = Loader(lazy(() => import('src/app/feature/Auth/pages/Login')));

const RecoveryPassword = Loader(
  lazy(
    () => import('src/app/feature/Auth/PasswordRecovery/pages/PasswordRecovery')
  )
);

const routesAuth: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Login />
      },
      {
        path: '/passwordRecovery',
        element: <RecoveryPassword />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  }
];

export default routesAuth;
