import {
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';

import NotificationSoundAssistance from 'src/app/assets/audio/asistence-tone.mp3';
import NotificationSoundOrderKitchen from 'src/app/assets/audio/kitchen-notification.mp3';
import {
  useAppSelector,
  useAppDispatch
} from 'src/app/core/config/reduxCustomHooks';
import {
  listAssistanceNotifications,
  clearAsistanceNotifications
} from 'src/app/core/redux/actions/assistance/AssistanceActions';
import {
  listOrderNotifications,
  clearOrderNotifications
} from 'src/app/core/redux/actions/orders/OrdersActions';
import { AssistanceDao } from 'src/app/core/api/asisstance/dao/assistance.dao';
import { OrdersDao } from 'src/app/core/api/orders/dao/orders.dao';
import { ResponseAssistanceProps } from 'src/app/feature/Assistance/models/Assistance';
import { Order } from 'src/app/feature/Orders/models/Orders';
import {
  getDiferenceDate,
  momentInstance
} from 'src/app/core/config/momentInstance';
import { UsersRole } from 'src/app/core/config/enums';
import { UserAuth } from 'src/app/feature/Users/models/User';

import style from './styles.module.scss';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

let timerId = null;
let timerOrderId = null;

function HeaderNotifications() {
  const user: UserAuth = JSON.parse(localStorage.getItem('user'));
  const dispatch = useAppDispatch();
  const assistanceNotifications = useAppSelector(
    (state) => state.assistance.assistanceNotifications
  );
  const ordersNotifications = useAppSelector(
    (state) => state.orders.ordersNotifications
  );
  const ref = useRef(null);
  const audioAssistance = useRef<HTMLAudioElement>(null);
  const audioOrderKitchen = useRef<HTMLAudioElement>(null);
  const [time, setTime] = useState(0);
  const [timeOrder, setTimeOrder] = useState(0);
  const [isOpen, setOpen] = useState<boolean>(false);

  const allowUsersAssiatance = [
    UsersRole.MANAGER.toUpperCase(),
    UsersRole.OWNER.toUpperCase(),
    UsersRole.HOST.toUpperCase()
  ];

  const allowUsersOrders = [UsersRole.KITCHEN.toUpperCase()];

  const navigate = useNavigate();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const redirectAssistance = () => {
    navigate('/assistance');
    handleClose();
  };

  const redirectOrders = () => {
    if (user.role.toUpperCase() === UsersRole.HOST.toUpperCase()) {
      navigate('/orders');
    } else {
      navigate('/panel');
    }
    handleClose();
  };

  const playNotificationAssistance = () => {
    audioAssistance.current.play().catch(() => {
      document.addEventListener(
        'click',
        () => {
          audioAssistance.current.play();
        },
        { once: true }
      );
    });
  };

  const playNotificationOrderKitchen = () => {
    audioOrderKitchen.current.play().catch(() => {
      document.addEventListener(
        'click',
        () => {
          audioOrderKitchen.current.play();
        },
        { once: true }
      );
    });
  };

  const getLabelAssiatance = () => {
    if (assistanceNotifications.length === 1) {
      return `Hay ${assistanceNotifications.length} solicitud de asistencia`;
    } else if (assistanceNotifications.length > 1) {
      return `Hay ${assistanceNotifications.length} solicitudes de asistencia`;
    } else {
      return '';
    }
  };

  const getLabelOrder = () => {
    if (ordersNotifications.length === 1) {
      return `Hay ${ordersNotifications.length} orden nueva`;
    } else if (ordersNotifications.length > 1) {
      return `Hay ${ordersNotifications.length} ordenes nuevas`;
    } else {
      return '';
    }
  };

  const getNotificationsAssiatance = async () => {
    const response: AxiosResponse<ResponseAssistanceProps> =
      await AssistanceDao.list(1, 100, user.selectedAccount, null);
    const arrayNotifications = response.data.docs;
    dispatch(listAssistanceNotifications(arrayNotifications));
    if (arrayNotifications.length > 0) {
      setTime(
        getDiferenceDate(
          moment(arrayNotifications[0].createdAt.replace('Z', '')),
          momentInstance(),
          'minutes'
        )
      );
      const notification: string = localStorage.getItem(
        'notificationAssiatance'
      );
      if (notification === null) {
        const createdAt = arrayNotifications[0].createdAt;
        localStorage.setItem('notificationAssiatance', createdAt);
        playNotificationAssistance();
      } else {
        const createdAt = arrayNotifications[0].createdAt;
        const seconds = getDiferenceDate(
          moment(notification.replace('Z', '')),
          moment(createdAt.replace('Z', '')),
          'seconds'
        );
        if (seconds > 0) {
          localStorage.setItem('notificationAssiatance', createdAt);
          playNotificationAssistance();
        }
      }
    }
  };

  const getNotificationsOrders = async () => {
    const response: AxiosResponse<Order[]> = await OrdersDao.panel();
    const arrayNotifications = response.data?.reverse();
    dispatch(listOrderNotifications(arrayNotifications));
    if (arrayNotifications.length > 0) {
      setTimeOrder(
        getDiferenceDate(
          moment(arrayNotifications[0].createdAt.replace('Z', '')),
          momentInstance(),
          'minutes'
        )
      );
      const notification: string = localStorage.getItem('notificationOrders');
      if (notification === null) {
        const createdAt = arrayNotifications[0].createdAt;
        localStorage.setItem('notificationOrders', createdAt);
        playNotificationOrderKitchen();
      } else {
        const createdAt = arrayNotifications[0].createdAt;
        const seconds = getDiferenceDate(
          moment(notification.replace('Z', '')),
          moment(createdAt.replace('Z', '')),
          'seconds'
        );
        if (seconds > 0) {
          localStorage.setItem('notificationOrders', createdAt);
          playNotificationOrderKitchen();
        }
      }
    }
  };

  useEffect(() => {
    if (allowUsersAssiatance.includes(user.role.toUpperCase())) {
      getNotificationsAssiatance();
      timerId = setInterval(() => {
        getNotificationsAssiatance();
      }, 10000);
    }
    if (allowUsersOrders.includes(user.role.toUpperCase())) {
      getNotificationsOrders();
      timerOrderId = setInterval(() => {
        getNotificationsOrders();
      }, 10000);
    }

    return () => {
      clearInterval(timerId);
      clearInterval(timerOrderId);
      dispatch(clearAsistanceNotifications());
      dispatch(clearOrderNotifications());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <audio ref={audioAssistance} src={NotificationSoundAssistance} />
      <audio ref={audioOrderKitchen} src={NotificationSoundOrderKitchen} />
      <Tooltip arrow title="Notificaciones">
        <IconButton
          color={
            assistanceNotifications.length || ordersNotifications.length
              ? 'error'
              : 'secondary'
          }
          ref={ref}
          onClick={handleOpen}
        >
          <NotificationsBadge
            badgeContent={
              assistanceNotifications.length + ordersNotifications.length
            }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="h5">Notificaciones</Typography>
            <CancelIcon
              onClick={handleClose}
              className={style.closeNotifications}
            />
          </Box>
        </Box>
        <Divider />
        <List sx={{ p: 0, minWidth: 350 }}>
          {assistanceNotifications.length > 0 ? (
            <ListItem
              sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
            >
              <Box
                flex="1"
                onClick={redirectAssistance}
                sx={{ cursor: 'pointer' }}
                title="Click para ir a pantalla de Asistencia"
              >
                <Box display="flex" justifyContent="space-between">
                  <React.Fragment>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Solicitudes de asistencia
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ textTransform: 'none' }}
                    >
                      {`Hace ${time} minutos`}
                    </Typography>
                  </React.Fragment>
                </Box>
                <Typography
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {getLabelAssiatance()}
                </Typography>
              </Box>
            </ListItem>
          ) : (
            ''
          )}

          {ordersNotifications.length > 0 ? (
            <ListItem
              sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
            >
              <Box
                flex="1"
                onClick={redirectOrders}
                sx={{ cursor: 'pointer' }}
                title="Click para ir a Monitor de Órdenes"
              >
                <Box display="flex" justifyContent="space-between">
                  <React.Fragment>
                    <Typography sx={{ fontWeight: 'bold' }}>Ordenes</Typography>
                    <Typography
                      variant="caption"
                      sx={{ textTransform: 'none' }}
                    >
                      {`Hace ${timeOrder} minutos`}
                    </Typography>
                  </React.Fragment>
                </Box>
                <Typography
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {getLabelOrder()}
                </Typography>
              </Box>
            </ListItem>
          ) : (
            ''
          )}
          {!assistanceNotifications.length && !ordersNotifications.length ? (
            <ListItem
              sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
            >
              <Typography
                component="span"
                variant="body2"
                color="text.secondary"
              >
                Sin Notificaciones
              </Typography>
            </ListItem>
          ) : (
            ''
          )}
        </List>
      </Popover>
    </React.Fragment>
  );
}

export default HeaderNotifications;
